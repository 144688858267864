import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';

const PaymentProcess = () => {
    const params = useParams();
    const { checkout_id, method, environment } = params;
    const [brand, setBrands] = useState();

    const useScript = url => {
        useEffect(() => {
          const script = document.createElement('script');
          script.src = url;
          script.async = true;
          document.body.appendChild(script);
          return () => {
            document.body.removeChild(script);
          };
        }, [url]);
    };

    return (
        <div style={{direction: 'ltr', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
            <form action={process.env.REACT_APP_BASE_URL + '/my-account'} className="paymentWidgets" 
              data-brands={method}
            ></form>
            {useScript(
              process.env.REACT_APP_API_BASE_URL === 'https://staging.api.drs-space.sa'  || environment === 'staging' ? `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkout_id}` : `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${checkout_id}`)}
        </div>
    )
}



export default PaymentProcess;