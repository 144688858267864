import './our-partner.scss';

const OurPartner = () => {
    return(
        <div className="our-partner">
            {/* <div className="partner-header">
                <h4>شركاءنا</h4>
                <h2>الشركات المدعومة</h2>
                <span></span>
            </div>
            <div className="partner-data">
                <img src={process.env.PUBLIC_URL + '/assets/images/UCA.png'} alt="" />
                <img src={process.env.PUBLIC_URL + '/assets/images/medGulf.png'} alt="" />
                <img src={process.env.PUBLIC_URL + '/assets/images/stc.png'} alt="" />
                <img src={process.env.PUBLIC_URL + '/assets/images/globemed.jpg'} alt="" />
                <img src={process.env.PUBLIC_URL + '/assets/images/bupa.png'} alt="" />
                <img src={process.env.PUBLIC_URL + '/assets/images/mada.png'} alt="" />
            </div> */}
        </div>
    )
}

export default OurPartner;