import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getMedicalCenterApi } from '../../../data/api/search-api';
import DoctorData from "../component/doctor-data/doctor-data";
import './view-medical-center.scss';
import MedicalInfo from "../component/medical-info/medical-info";


const ViewMedicalCenter = () => {
    const params = useParams();
    const { id } = params;
    const [doctor, setDoctor] = useState()

    useEffect(() => {
        getDoctor();
        window.scrollTo(0, 0)
    }, [])

    const getDoctor = () => {
        getMedicalCenterApi(id).then(
            res => {
                setDoctor(res.data.data)
            }
        )
    }

    return(
        <div className="view-medical">
            <DoctorData doctors={doctor} />
            <MedicalInfo doctors={doctor} />
        </div>
    )
}

export default ViewMedicalCenter;