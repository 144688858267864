import './our-service.scss';

const OurService = () => {
    return(
        <div className="our-service">
            <div className="service-header">
                <h4>الخدمات الرئيسية</h4>
            </div>
            <div className="service-data">
                <div className="right">
                    <img src={process.env.PUBLIC_URL + '/assets/images/circle.svg'} />
                    <img src={process.env.PUBLIC_URL + '/assets/images/main-service.svg'} />
                    <img src={process.env.PUBLIC_URL + '/assets/images/services2.svg'} />
                </div>
                <div className="left">
                    <p>ابحث .. قارن .. احجز زيارتك للمركز مع أكفأ الأطباء</p>
                    <p>تحدث مع طبيبك بشكل فوري عبر مكالمات صوتية مرئية</p>
                    <p>احجز خدمات الكشف المنزلي.. التحاليل المخبرية .. العلاج الطبيعي.. التمريض المنزلي.. التطعيمات</p>
                </div>
            </div>
        </div>
    )
}

export default OurService;