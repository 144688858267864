import axios from "./base-api";

export const contactUsApi = (data) => {
    return axios({
        method: 'post',
        url: `/contact-us`,
        data:data
    })
}

export const submitOfferForm = (data) => {
    return axios({
        method: 'post',
        url: `/submit-offer-form`,
        data:data
    })
}

// notifications
export const getNotifications = () =>
  axios({
    method: "get",
    url: `/notifications`,
   
  });

export const getUnreadNotificationsCount = () =>
  axios({
    method: "get",
    url: `/notifications/unread-count`,
   
  });

export const markNotificationAsRead = () =>
  axios({
    method: "post",
    url: `/notifications/mark-read`,
   
  });
