import './doctor-data.scss';
import { Rate } from 'antd';

const DoctorData = ({doctors}) => {
    return (
        <div className="doctors-data">
            <div className="doctor-image">
                <img src={doctors?.image} alt="" />
            </div>
            <div className="doctor-name">
                {doctors?.name ? <p>{doctors?.name}</p> : ''}
                {doctors?.description ? <p>{doctors?.description}</p> : '' }
                {doctors?.medicalSpecialities[0]?.name ? <p>{doctors?.medicalSpecialities[0]?.name}</p> : ''}
            </div>
            {/* <div className="doctor-rate">
                <div className="rate">
                    <img src={process.env.PUBLIC_URL + '/assets/images/eye.svg'} alt="" />
                    <p>150.435 مشاهدات</p>
                </div>
                <div className="rate">
                    <Rate allowHalf disabled defaultValue={5} />
                    <p>التقييم العام من 876 زائر</p>
                </div>
            </div> */}
        </div>
    )
}

export default DoctorData;