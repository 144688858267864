import './doctors-result.scss';
import { Skeleton, Empty, Pagination } from "antd";
import { Rate } from 'antd';
import { useHistory } from 'react-router';
import { useState } from 'react';
import DoctorElement from '../../../view-medical-center/component/doctor-element/doctor-element';


const DoctorsResult = ({doctors, loading, meta, getDoctorsMethod}) => {
    const history = useHistory();
    const [page, setPage] = useState(1)

    const getMoreDoctors = () => {
        getDoctorsMethod(meta?.current_page + 1);
    }

    const onChangePage = (event) => {
        setPage(event)
        getDoctorsMethod(event);
    }

    const navigateToDoctor = (event, id) => {
        event.stopPropagation();
        if (window.location.href.includes('video')) {
            history.push(`/doctor/${id}?video=true`);
        } else {
            history.push(`/doctor/${id}`)
        }
    }

    const navigateToMedical = (event, id) => {
        event.stopPropagation();
        history.push(`/medical-center/${id}`)
    }

    return(
        <div className="doctors-list-result">
            <div className="header-title">
                <p className="">كل التخصصات <span>({meta?.total}) طبيب وطبيبة</span></p>
                {/* <div className="sort col-6">
                    <label for="">الترتيب</label>
                    <Select defaultValue="lucy" style={{ width: '100%' }}>
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="disabled" disabled>
                            Disabled
                        </Option>
                        <Option value="Yiminghe">yiminghe</Option>
                    </Select>
                </div> */}
            </div>

            <div className="">
                <Skeleton className="" active={true} loading={loading} avatar>
                    {doctors?.length == 0 ? (
                        <div className="">
                            <Empty
                                description={
                                <span className="font-Lsmall weight-600 text-theme-color">
                                    لا يوجد أطباء
                                </span>
                                }
                            />
                        </div>
                    ) : (
                        doctors?.map((doctor, index) => (
                            <DoctorElement key={index} doctor={doctor} />
                        ))
                    )}
                    {meta?.current_page * 15 < meta?.total ?<Pagination pageSize={15} defaultCurrent={page} total={meta?.total} onChange={onChangePage} />: '' }
                </Skeleton>
                
            </div>

        </div>
    )
}

export default DoctorsResult;