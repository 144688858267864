import "./search-component.scss";
import { Tabs } from 'antd';
import SearchDoctor from "../search-doctor/search-doctor";
import OnlineSearch from "./online-search/online-search";
import SearchLab from "../search-lab/search-lab";

const { TabPane } = Tabs;


const SearchComponent = () => {

    const callback = (key) => {
        console.log(key);
    }

    return (
        <div className="search-component">
            <div className="content">
                <Tabs
                    defaultActiveKey="1"
                    onChange={callback}
                    type="card"
                    items={[1,2].map((Icon, i) => {
                        const id = String(i + 1);
                        return {
                            label: i === 0 ? (
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/doctor-tab.svg'} alt='' />
                                    <span>احجز زيارتك مع أفضل الأطباء</span>
                                </div>
                                ): 
                                (
                                    <div>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/home-tab.svg'} alt='' />
                                        <span>خدمات طبية في منزلك</span>
                                    </div>
                                ),
                        key: id,
                        children: i === 0 ? <SearchDoctor /> : <SearchLab />,
                        };
                    })}
                />
                {/* <Tabs onChange={callback} type="card">
                    <Tabs.TabPane tab={doctorLabel} key="1">
                        <SearchDoctor />
                    </Tabs.TabPane>
                    <TabPane tab="رعاية منزلية" key="4">
                        <SearchLab />
                    </TabPane>
                </Tabs> */}
            </div>
        </div>
    )
}

export default SearchComponent;