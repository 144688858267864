import React from 'react';
import { useParams } from "react-router-dom";
import './doctor-info.scss';
import { getDoctorAppointmentApi } from '../../../../data/api/search-api';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Login from '../../../auth/page/login/login';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Skeleton } from "antd";
import AppointmentSlider from '../../../appointment-slider/appointment-slider'

const DoctorInfo = ({doctors, offerId, user}) => {
    const params = useParams();
    const { id } = params;
    const [appointments, setAppointments ] = useState([]);
    const [selectedTime, setSelectedTime] = useState();
    const [selectedOFfer, setSelectedOffer] = useState();
    const [showModal, setShowModal] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [appointmentsBody, setAppointmentsBody] = useState({});
    const [dayIndex, setDayIndex] = useState(0);
    const history = useHistory();
    const search = useLocation().search;
    const video = new URLSearchParams(search).get('video');
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getAppointments(video);
    }, []);

    const getAppointments = (video = false) => {
        setLoading(true)
        getDoctorAppointmentApi(id, offerId, video).then(
            (res) => {
                const appointmentArray = offerId ? res.data.data.filter(element => element.offer) : res.data.data
                setAppointments(appointmentArray)
                const result = appointmentArray.reduce(function (r, a) {
                    r[a.day] = r[a.day] || [];
                    r[a.day].push(a);
                    return r;
                }, Object.create(null));
                const resultKeys = Object.keys(result).sort();
                const resultSorted = {}
                resultKeys.forEach(key => {
                    resultSorted[key] = result[key];
                })
                setAppointmentsBody(resultSorted);
                setLoading(false)
            }
        )
    }

    const onSelectOffer = (value) => {
        localStorage.removeItem('offer')
        setSelectedOffer(value)
        if (value) {
            localStorage.setItem('offer', JSON.stringify(value))
        }
    }

    const onBookingDoctor = (event) => {
        setSelectedTime(event)
        setButtonDisabled(false)
        if (!event) {
            setButtonDisabled(true)
            return;
        }
        if (user) {
            history.push("/booking-doctor/" + event + '/' + id + `?video=${video}&offer_id=${offerId}`);
        } else {
            localStorage.setItem('previous-link', history?.location?.pathname)
            setShowModal(true)
        }
    }

    const closeModalHandler = () => {
        setShowModal(false);
    }

    return(
        <React.Fragment>
            <Skeleton className="" active={true} loading={loading} avatar>
                <div className="doctors-info-container">
                    <div className="left">
                        <div className='doctor-name-container'>
                            <div className="doctor-image">
                                <img src={doctors?.image} alt="" />
                            </div>
                            <div className="doctor-name">
                                {doctors?.name ? <p>دكتور/ {doctors?.name}</p> : ''}
                                {doctors?.description ? <p>{doctors?.description}</p> : '' }
                                {doctors?.medical_specialties[0]?.name ? <p>{doctors?.medical_specialties[0]?.name}</p> : ''}
                            </div>
                        </div>
                        <div className="">
                            <div className="doctor-header">
                                <img src={process.env.PUBLIC_URL + '/assets/images/info-circle.svg'} alt="" />
                                <p>نبذة عن الدكتور</p>
                            </div>
                            <div className="info">
                                <p>{doctors?.description}</p>
                            </div>
                        </div>

                        <div className="">
                            <div className="doctor-header">
                                <img src={process.env.PUBLIC_URL + '/assets/images/doctor-icon.svg'} alt="" />
                                <p> شركات التأمين</p>
                            </div>
                            <div className="specialties">
                                {doctors?.medical_center?.insuranceCompanies?.map((element, index) => (
                                    <span key={index} className="">{element?.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="">
                            <div className="doctor-header">
                                <img src={process.env.PUBLIC_URL + '/assets/images/doctor-icon.svg'} alt="" />
                                <p>المركز التابع له</p>
                            </div>
                            <div className="info">
                                <div className="medical-center">
                                    <p>{doctors?.medical_center?.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="right" style={{"paddingLeft": "0px"}}>
                        <div className="booking-info">
                            <span>معلومات الحجز</span>
                        </div>

                        <div className='appointment-data'>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/images/price.svg'} alt="" />
                                <span>سعر الكشف: </span>
                                {video == 'true' ? <span>{doctors?.video_fees} ريال</span> : <span>{doctors?.clinic_fees} ريال</span>}
                            </div>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                <a target="_blank" href={`https://maps.google.com/?q=${doctors?.latitude},${doctors?.longitude}`}>
                                    <p>{doctors?.address}</p>
                                </a>
                            </div>
                        </div>


                        <div className="booking-info online">
                            <span style={{"fontWeight": "bold"}}>اختار موعد الحجز</span>
                            <div className="appointments">
                                <div className='timing'>
                                    {appointments?.length > 0  ?
                                        <AppointmentSlider isDoctor={true} onSelectOffer={onSelectOffer} onBookingDoctor={onBookingDoctor} appointments={appointmentsBody} />
                                        :
                                        <p>لا يوجد حجوزات</p>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {showModal && <Modal title="" visible={showModal} footer={null} maskClosable={false} onCancel={closeModalHandler} width={600}>
                    <Login url={`/booking-doctor/${selectedTime}/${id}`} />
                </Modal>}
            </Skeleton>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(DoctorInfo);