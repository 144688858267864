import './benefits.scss';

const Benefits = () => {
    return(
        <div className="benefits">
            <div className="benefits-header">
                <h4>مميزاتنا</h4>
            </div>
            <div className="benefits-data">
                <div className='element'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/others.svg'} />
                    <p>قيم تجربتك واستعرض تجارب الآخرين</p>
                </div>
                <div className='element'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/book.svg'} />
                    <p>احجز خدمات الرعاية المنزلية بسهولة</p>
                </div>
                <div className='element'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/explore.svg'} />
                    <p>أكتشف الأطباء القريبين منك</p>
                </div>
                <div className='element'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/pay.svg'} />
                    <p>طرق دفع سهلة</p>
                </div>
                <div className='element'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/compare.svg'} />
                    <p>قارن أفضل العروض</p>
                </div>
                <div className='element'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/medical.svg'} />
                    <p>استشارات طبية فورية</p>
                </div>
                <div className='element'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/easy.svg'} />
                    <p>حجزك أسهل مع أكفأ الأطباء</p>
                </div>
                {/* <div className='element'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/efficent.svg'} />
                    <p>حجزك أسهل مع أكفأ الأطباء بسهولة</p>
                </div> */}
            </div>
        </div>
    )
}

export default Benefits;