import axios from "./base-api";


export const searchApi = (data) =>{
    for (const key of Object.keys(data)) {
        if (data[key] == "null") {
          delete data[key];
        }
    }
    return axios({
        method: "get",
        url: `/doctors`,
        params: data
    });
}

export const getFeaturedDoctors = (data) =>{
    for (const key of Object.keys(data)) {
        if (data[key] == "null") {
          delete data[key];
        }
    }
    return axios({
        method: "get",
        url: `/featured-doctors`,
        params: data
    });
}

export const searchMedicalApi = (data) =>{
    for (const key of Object.keys(data)) {
        if (data[key] == "null") {
          delete data[key];
        }
    }
    return axios({
        method: "get",
        url: `/medical_centers`,
        params: data
    });
}

export const medicalSpecialties = () => 
    axios({
        method: 'get',
        url: '/medical-specialties'
    })

export const offersMedicalSpecialties = () => 
    axios({
        method: 'get',
        url: '/offer-specialties'
    })

export const vaccinationApi = () => 
    axios({
        method: 'get',
        url: '/vaccinations'
    })

export const subMedicalSpecialtiesApi = (data) => 
    axios({
        method: 'get',
        url: '/sub-medical-specialties',
        params: data
    })

export const titlesApi = () => 
    axios({
        method: 'get',
        url: '/titles',
    })

export const insuranceCompaniesApi = () => 
    axios({
        method: 'get',
        url: '/insurance-companies'
    })

export const governorates = () => 
    axios({
        method: 'get',
        url: '/governorates'
    })

export const getCities = (data) => 
    axios({
        method: 'get',
        url: '/cities',
        params: data
    })

export const getDoctorApi = (id) => 
    axios({
        method: 'get',
        url: `/doctors/${id}`,
    })

export const getMedicalCenterApi = (id) => 
    axios({
        method: 'get',
        url: `/medical_centers/${id}`,
    })

export const getDoctorAppointmentApi = (id, offerId, video = false) => 
    axios({
        method: 'get',
        url: `/doctors/${id}/appointments?type=${video ? 2 : 1}&offer_id=${offerId}`,
    })

export const getAllLabsApi = (data) => {
    for (const key of Object.keys(data)) {
        if (data[key] == "null") {
          delete data[key];
        }
    }

    return axios({
        method: 'get',
        url: `/labs`,
        params: data
    })
}

export const getLabSpecialties = () => {
    return axios({
        method: 'get',
        url: '/lab-specialties'
    })
}

export const getLabApi = (id, offerId) => 
    axios({
        method: 'get',
        url: `/labs/${id}?offer_id=${offerId}`,
    })