import './medical-file.scss';
import { Tabs } from 'antd';
import OrdersList from '../orders-list/orders-list';
import PublicInfo from '../public-info/public-info';
import MedicalHistory from '../medical-history/medical-history';
import LabHistory from '../lab-history/lab-history';


const { TabPane } = Tabs;

const MedicalFile = () => {
    const callback = (key) => {
        console.log(key);
    }

    return (
        <div className="labOrders-container">
            <div className="content">
                <Tabs onChange={callback} type="card">
                    <TabPane tab="المعلومات العامة" key="1">
                        <PublicInfo />
                    </TabPane>
                    <TabPane tab="تقارير الأطباء" key="2">
                        <MedicalHistory />
                    </TabPane>
                    <TabPane tab="تقارير المختبرات" key="3">
                        <LabHistory />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default MedicalFile;