import './lab-data.scss';
import { Rate } from 'antd';

const LabData = ({doctors}) => {
    return (
        <div className="doctors-data">
            <div className="doctor-image">
                <img src={doctors?.image} alt="" />
            </div>
            <div className="doctor-name">
                {doctors?.name ? <p>{doctors?.name}</p> : ''}
                {doctors?.description ? <p>{doctors?.description}</p> : '' }
            </div>
        </div>
    )
}

export default LabData;