import './booking-cart.scss';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { getLabApi } from '../../data/api/search-api';
import { Skeleton } from "antd";
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import Login from '../auth/page/login/login';
import { useSelector } from 'react-redux';

const BookingCart = () => {
    const params = useParams();
    const { user } = useSelector((state) => state);
    const { id } = params;
    const [lab, setLab] = useState('');
    const [loading, setLoading] = useState(false);
    const [savedLab, setSavedLab] = useState();
    const [savedAnalysis, setSavedAnalysis] = useState();
    const [newSavedLab, setNewSavedLab] = useState();
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setSavedLab(() => JSON.parse(localStorage.getItem('lab')));
        setSavedAnalysis(() => JSON.parse(localStorage.getItem('savedAnalysis')));
        getLab();
    }, []);

    const getLab = () => {
        setLoading(true);
        getLabApi(id).then(
            res => {
                setLab(res.data.data);
                setLoading(false);
            }
        )
    }

    const confirmBooking = () => {
        if (!user) {
            setShowModal(true)
        } else {
            localStorage.setItem('savedAnalysis', JSON.stringify(savedAnalysis));
            history.push(`/booking-lab/${id}`);
        }
    }

    const removeAnalysis = (index) => {
        setSavedAnalysis(savedAnalysis.filter((element, i) => i !== index));
    }

    const closeModalHandler = () => {
        setShowModal(false);
    }

    return(
        <div className="booking-cart">
            <div className="cart-header">
                <p>سلة الحجز</p>
            </div>
            <Skeleton className="" active={true} loading={loading} avatar>
                <div className="content">
                    <div className="right">
                        <div className="data">
                            <div className="name">
                                <div className="image">
                                    <img src={lab.image} alt="" />
                                </div>
                                <div>
                                    <p>{lab.name}</p>
                                    <p>{lab.description}</p>
                                </div>
                            </div>
                            <div className="price">
                                <p>الحساب الكلى: <span>{savedAnalysis?.reduce((result, item) => (item?.price + result), 0)} ر.س</span></p>
                                <div className="address">
                                    <a target="_blank" href={`https://maps.google.com/?q=${lab?.latitude},${lab?.longitude}`}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                        <p>{lab?.address}</p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="data services">
                            <h3>الخدمات</h3>
                                {
                                    savedAnalysis?.map((lab, index) => (
                                        <div key={index}>
                                            <div className="vaccine-name">
                                                <img src={process.env.PUBLIC_URL + '/assets/images/lab.svg'} alt="" />
                                                <p>{lab?.labSpecialty?.name}</p>
                                            </div>
                                            <div className="delete" onClick={() => removeAnalysis(index)}>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/delete.png'} alt="" />
                                                <p>إزالة</p>
                                            </div>
                                        </div>
                                        )
                                    )
                                }
                        </div>
                    </div>
                    <div className="left">
                        <p>مراجعة السعر</p>
                        {
                            savedAnalysis?.map((lab, index) => (
                                <div className="vaccine-price" key={index}>
                                    <p>{lab?.labSpecialty?.name}</p>
                                    {!lab?.offer?.id && <p>{lab?.price}</p>}
                                    {lab?.offer?.id && <p>{lab?.offer?.price_after_discount}</p>}
                                </div>
                            ))
                        }
                        <div className="total">
                            <p>الأجمالى</p>
                            <p>{savedAnalysis?.reduce((result, item) => ((!item?.offer?.id ? item?.price : item?.offer?.price_after_discount) + result), 0)} ر.س</p>
                        </div>
                    </div>
                </div>
                <div className="cart-footer">
                    <button onClick={confirmBooking} disabled={!savedAnalysis?.length}>تأكيد الحجز</button>
                </div>
            </Skeleton>
            {showModal && <Modal title="" visible={showModal} footer={null} maskClosable={false} onCancel={closeModalHandler} width={600}>
                <Login url={`/booking-lab/${id}`} />
            </Modal>}
        </div>
    )
}

export default BookingCart;