import './booking-lab.scss';
import { useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from 'react';
import { message, Radio } from 'antd';
import { getLabApi } from '../../data/api/search-api';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { Upload, Button } from 'antd';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { Skeleton } from "antd";
import { makeOrderApi } from '../../data/api/reservation-api';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import countryList from 'react-select-country-list'

const BookingLab = () => {
    const params = useParams();
    const { id } = params;
    const [lab, setLab] = useState()
    const [valueBookingName, setValueBookingName] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [fileId, setFileId] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [value, setValue] = useState(2);
    const [savedAnalysis, setSavedAnalysis] = useState();
    const [savedLab, setSavedLab] = useState();
    const [date, setDate] = useState('');
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [street, setStreet] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [postCode, setPostCode] = useState();
    const [country, setCountry] = useState('')
    const [offerId, setOfferId] = useState('')
    const countries = useMemo(() => countryList().getData(), [])

    const upload = {
        name: 'file',
        maxCount: 1,
        action: `${process.env.REACT_APP_API_BASE_URL}/api/users/upload`,
        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                setFileId(info.file.response.path);
            } else if (info.file.status === 'error') {
                
            }
        },
        data: { mime_type:"image"}
    }

    const closeModalHandler = () => {
        setShowModal(false);
    }

    const changeCountry = value => {
        setCountry(value)
    }

    useEffect(() => {
        setSavedAnalysis(() => JSON.parse(localStorage.getItem('savedAnalysis')));
        setSavedLab(() => JSON.parse(localStorage.getItem('lab')));
        setOfferId(() => localStorage.getItem('offerId') !== 'undefined' ? JSON.parse(localStorage.getItem('offerId')) : null);
        getLab();
        window.scrollTo(0, 0)
    }, [])

    const getLab = () => {
        setLoading(true);
        getLabApi(id).then(
            res => {
                setLab(res.data.data);
                setLoading(false);
            }
        )
    }   
    
    const onChange = e => {
        setValue(e.target.value);
    };

    const onSubmit = () => {
        if (!date) {
            return;
        }
        if (value != 1 && (!email || !firstName || !lastName || !street || !city || !state || !country || !postCode)) {
            return;
        }
        let body = {}

        body = {
            lab_id: lab.id,
            specialties: savedAnalysis?.map(lab => lab?.labSpecialty?.id),
            attendance_date: date,
            attend_at: date,
            payment_method: value,
            customer: {
                email: email,
                givenName: firstName,
                surname: lastName
            },
            billing: {
                street1: street,
                city: city,
                state: state,
                country: country,
                postcode: postCode
            }
        }

        if (offerId) {
            body['offer_id'] = offerId;
        }

        if (savedLab?.doctor?.id) {
            body['doctor_reservation_id'] = savedLab?.doctor?.id
        }

        // setLoading(true);
        makeOrderApi(body).then(
            res => {
                setLoading(false);
                if (res.data.data?.payment_transaction?.checkout_id) {
                    history.push(`/payment-process/${res.data.data?.payment_transaction?.checkout_id}/${res.data.data?.payment_transaction?.payment_method_string}`);
                } else {
                    // setShowModal(true);
                    history.push('/success-booking')
                }
                localStorage.setItem('offerId', offerId)
            }
        ).catch(error => {
            message.error(Object.values(error.response.data?.errors)[0] || 'حدث خطأ ما')
        })
    }

    const dateChanged = (value, date) => {
        setDate(date)
    }

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    }

    return(
        <div className="booking-lab">
            <h2>مراجعة وتأكيد الحجز</h2>
            <Skeleton className="" active={true} loading={loading} avatar>
            <div className="doctor-content">
            <div className="right">
                        <div className="data">
                            <div className="name">
                                <div className="image">
                                    <img src={lab?.image} alt="" />
                                </div>
                                <div>
                                    <p>{lab?.name}</p>
                                    <p>{lab?.description}</p>
                                </div>
                            </div>
                            <div className="price">
                                <p>الحساب الكلى: <span>{savedAnalysis?.reduce((result, item) => ((!item?.offer?.id ? item?.price : item?.offer?.price_after_discount) + result), 0)} ر.س</span></p>
                                <div className="address">
                                    <a target="_blank" href={`https://maps.google.com/?q=${lab?.latitude},${lab?.longitude}`}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                        <p>{lab?.address}</p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="data services">
                            <h3>الخدمات</h3>
                            {
                                savedAnalysis?.map((item, index) => (
                                    <div key={index}>
                                        <div className="vaccine-name">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/lab.svg'} alt="" />
                                            <p>{item?.labSpecialty?.name}</p>
                                        </div>
                                        {/* <div className="delete">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/delete.png'} alt="" />
                                            <p>إزالة</p>
                                        </div> */}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="left">
                        <p>مراجعة السعر</p>
                        {
                            savedAnalysis?.map((item, index) => (
                                <div key={index} className="vaccine-price">
                                    <p>{item?.labSpecialty?.name}</p>
                                    {!item?.offer?.id && <p>{item?.price} ر.س</p>}
                                    {item?.offer?.id && <p>{item?.offer?.price_after_discount} ر.س</p>}
                                </div>
                            ))
                        }
                        <div className="total">
                            <p>الأجمالى</p>
                            <p>{savedAnalysis?.reduce((result, item) => ((!item?.offer?.id ? item?.price : item?.offer?.price_after_discount) + result), 0)} ر.س</p>
                        </div>
                    </div>
            </div>
            <div className="booking-steps">
                <div className="steps">
                    <span className="circle"></span>
                    <span className="line"></span>
                    <span className="circle"></span>
                    <span className="line"></span>
                    <span className="circle"></span>
                    <span className="line"></span>
                    <span className="circle"></span>
                    {/* <span className="line"></span>
                    <span className="circle"></span> */}
                </div>
                <div className="booking-data">
                    <div className="step-one">
                        <p className="step-title">بيانات الحجز</p>
                        { valueBookingName === 1 ? <Upload {...upload}>
                                <Button>إرفاق الملف الطبى</Button>
                            </Upload> : ''
                                // <div className="first-row">
                                //     <div className="field">
                                //         <label>الاسم بالكامل<span>*</span></label>
                                //         <input type="text" name="" onInput={(event) => setName(event.target.value)} />
                                //     </div>
                                //     <div className="field">
                                //         <label>رقم الجوال<span>*</span></label>
                                //         <input type="text" style={{'direction': 'ltr', 'textAlign': 'right'}} name="" onInput={(event) => setMobile(event.target.value)} />
                                //     </div>
                                // </div> 
                        }
                    </div>
                    <div className="step-two">
                        <p className="step-title">اختيار تاريخ الحجز</p>
                        <div className="date">
                            <div className="month">
                                <label>التاريخ</label>
                                <DatePicker showTime showNow={false} disabledDate={disabledDate} placeholder=" التاريخ" onChange={dateChanged} />
                            </div>
                        </div>
                    </div>
                    <div className="step-two">
                        <p className="step-title">اختيار طريقة الدفع</p>
                        <p>برجاء اختيار طريقة الدفع</p>
                        <Radio.Group onChange={onChange} value={value}>
                            {/* <Radio value={1}>كاش</Radio> */}
                            <Radio value={2}>فيزا</Radio>
                            <Radio value={3}>ماستر كارد</Radio>
                            <Radio value={4}>مدى</Radio>
                            <Radio value={5}>stc pay</Radio>
                        </Radio.Group>
                    </div>

                    {value != 1 ? <div className="step-three">
                        <p className="step-title">الدفع ببطاقة الائتمان</p>
                        <div className="payment">
                            <img src={process.env.PUBLIC_URL + '/assets/images/visa.svg'} alt="" />
                            <img src={process.env.PUBLIC_URL + '/assets/images/mastercard.svg'} alt="" />
                        </div>
                        <div className="first-row">
                            <div className="field">
                                <label>البريد الألكترونى<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="second-row">
                            <div className="field">
                                <label>الإسم الأول<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div className="field">
                                <label>الإسم الأخير<span>*</span></label>
                                <input type="text" onInput={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                        <div className="thrid-row">
                            <div className="field">
                                <label>الدولة<span>*</span></label>
                                <Select placeholder="" options={countries} value={country} onChange={changeCountry} />
                            </div>
                            <div className="field">
                                <label>المدينة<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setCity(e.target.value)} />
                            </div>
                            <div className="field">
                                <label>المنطقة<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setState(e.target.value)} />
                            </div>
                            <div className="field">
                                <label>الشارع<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setStreet(e.target.value)} />
                            </div>
                        </div>
                        <div className='fourth-row'>
                            <div className="field">
                                <label>الرقم البريدى<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setPostCode(e.target.value)} />
                            </div>
                        </div>
                    </div> : ''}

                    <div className="step-four">
                        <button type="" onClick={onSubmit}>اتمام الحجز</button>
                    </div>
                </div>
            </div>

                {showModal && <Modal title="" visible={showModal} footer={null} closable={false} wrapClassName="success-modal" maskClosable={false} onCancel={closeModalHandler} width={600}>
                    <div className="booking-success">
                        <div className="image">
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/images/check.svg'} alt="" />
                            </div>
                        </div>
                        <div className="success-data">
                            <h3>تم تأكيد حجزك بنجاح</h3>
                            <p>تم إتمام الحجز بنجاح</p>
                            <button type="">
                                <Link to="/my-account">تفاصيل الحجز</Link>
                            </button>
                            <Link to="/home">الصفحة الرئيسية</Link>
                        </div>                    
                    </div>
                </Modal>}

                <Backdrop className='backdrop' open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Skeleton>
        </div>
    )
}

export default BookingLab;