import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';

import './header.scss';
import NotificationsSection from '../../../notification/notification';

const Header = (props) => {

    useEffect(() =>{
        const humburger = document.querySelector('.hamburger')
        const menu = document.querySelector('.list')

        humburger.addEventListener('click', () => {
            humburger.classList.toggle('active')
            menu.classList.toggle('active')
        })

        document.querySelectorAll('.nav-link').forEach(element => {
            element.addEventListener('click', () => {
                humburger.classList.remove('active')
                menu.classList.remove('active')
            })
        })
    }, [])

    return (
        <div className="header-component">
            <div className='header-logo'>
                <img src={process.env.PUBLIC_URL + '/assets/images/dr-logo.svg'} alt="Dr.Space" />
            </div>
            <div className='header-menu'>
                <ul className="list">
                    <li className='nav-link'><Link to="/home">الرئيسية</Link></li>
                    <li className='nav-link'><Link to="/search-medical">قائمة المراكز</Link></li>
                    <li className='nav-link'><Link to="/offers">العروض</Link></li>
                    {/* <li onClick={closeNav}><Link to="/search-labs">المختبرات</Link></li> */}
                    {/* <li onClick={closeNav}>الصيدلية</li> */}
                    <li className='nav-link'><a href='https://blog.drs-space.sa/' target='_blank'>المدونة</a></li>
                    <li className='nav-link' onClick={() => window.location.replace("/#contactUs")}>اتصل بنا</li>
                </ul>
            </div>
            <div className='header-account'>
                {!props.user ? 
                    <>
                        <Link to="/login">تسجيل الدخول</Link>
                        <Link className='header-register' to="/register">سجل الآن</Link>
                    </>
                    :
                    <>
                        <div className='my-account'>
                            <img src={process.env.PUBLIC_URL + '/assets/images/account.svg'} alt="account" />
                            <Link to="/my-account">حسابى</Link>
                        </div>
                        <div><NotificationsSection /></div>
                    </>
                }
            </div>
            <div className='hamburger'>
                <span className='bar'></span>
                <span className='bar'></span>
                <span className='bar'></span>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(Header);
