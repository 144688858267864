import './parteners.scss';

const Parteners = () => {
    return(
        <div className="parteners">
            <div className="parteners-header">
                <h4>شركاءنا</h4>
            </div>
            <div className='parteners-images'>
                <img src={process.env.PUBLIC_URL + '/assets/images/logo-07.svg'} />
                <img src={process.env.PUBLIC_URL + '/assets/images/logo-08.svg'} />
                <img src={process.env.PUBLIC_URL + '/assets/images/logo-09.svg'} />
                <img src={process.env.PUBLIC_URL + '/assets/images/logo-10.svg'} />
                <img src={process.env.PUBLIC_URL + '/assets/images/logo-11.svg'} />
                <img src={process.env.PUBLIC_URL + '/assets/images/logo-12.svg'} />
            </div>
        </div>
    )
}

export default Parteners;