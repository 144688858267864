import moment from 'moment';
import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './appointment-slider.scss'

function AppointmentSlider({appointments, onBookingDoctor, onSelectOffer, isDoctor = false}) {
    const [appointmentArray, setAppointmentArray] = useState([]);
    const [selectedTime, setSelectedTime] = useState();
    const [chunkSize, setChunkSize] = useState(3)

    useEffect(() => {
        setAppointmentArray([])
        const result = Object.keys(appointments).map((key) => [key, appointments[key]]);
        for (let i = 0; i < result.length; i += chunkSize) {
            const chunk = result.slice(i, i + chunkSize);
            setAppointmentArray(current => [...current, chunk])
        }
    }, [chunkSize, appointments])

    useEffect(() => {
        window.addEventListener("resize", resize);
        resize();
        return () => {
            window.removeEventListener("resize", resize);
        }
    }, [])

    const resize = () => {
        if (window.innerWidth <= 900 && window.innerWidth > 600) {
            setChunkSize(2)
        }
        else if (window.innerWidth <= 600) {
            console.log(window.innerWidth);
            setChunkSize(1)
        } else {
            setChunkSize(3)
        }
    }

    const onSelectTime = (value) => {
        setSelectedTime(value)
    }

    const getDayName = (day) => {
        if (new Date(day).getDate() === new Date().getDate() && new Date(day).getMonth() === new Date().getMonth()) {
            return 'اليوم'
        } else if (new Date(day).getDate() === new Date().getDate()+1 && new Date(day).getMonth() === new Date().getMonth()) {
            return 'غداً'
        }
        return day
    }
    

    return (
        <div className='slider-container'>
            {appointmentArray.length ? <Carousel indicators={false} interval={null}>
                {appointmentArray.map((appointment, index) => (<Carousel.Item key={index}>
                    <div className='cards-wrapper'>
                        {appointment.map((element, index) => (<div key={index} className='slider-card'>
                            <div className='slider-day'>
                                <p>{getDayName(element[0])}</p>
                            </div>
                            <div className='slider-appointment'>
                                {element[1].map((item, index) => (
                                <span
                                    className={selectedTime === item.uuid ? 'active' : ''} 
                                    key={index}
                                    onClick={() => {
                                        onSelectTime(item.uuid)
                                        if (isDoctor) onSelectOffer(item.offer)
                                    }}
                                >
                                    {item.start_time.split(':').splice(0,2).join(':')} {item.start_time.split(':').splice(0,1) <= 12 ? 'AM' : 'PM'}
                                </span>))}
                            </div>
                            <div className='slider-book'>
                                <button onClick={() => onBookingDoctor(selectedTime)}>
                                    {isDoctor ? 'احجز الان' : 'احجز'}
                                </button>
                            </div>
                        </div>))}
                    </div>
                </Carousel.Item>))}
            </Carousel> : ''}
        </div>
    );
}

export default AppointmentSlider;