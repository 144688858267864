export const nthIndex = (str, pat, n) => {
    var L = str.length, i = -1;
    while (n-- && i++ < L) {
        i = str.indexOf(pat, i);
        if (i < 0) break;
    }
    return i;
}

export const setStorage = (name, value, days) => {
    localStorage.setItem(name, value)
}

export const getStorage = (cname) => {
    return localStorage.getItem(cname)
}

export const deleteStorage = (name) => {
    localStorage.removeItem(name);
};

export const checkStorage = (c_name) => {
    if (localStorage.getItem(c_name) === null) {
        return false
    }
    else {
        return true
    }
}