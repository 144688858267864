import { Link } from 'react-router-dom';
import './footer.scss';

const Footer = () => {

    return (
        <div className="footer">
            <div className='footer-content'>
                <div className="apps">
                    <div>
                        <ul>
                            <li><Link to="/about-us">من نحن</Link></li>
                            <li>فريق دكتور سبيس</li>
                            <li>وظائف</li>
                            <li>الصحافة</li>
                        </ul>
                        <ul>
                            <li>اتصل بنا</li>
                            <li><Link to="/terms">سياسة المستخدم والخصوصية</Link></li>
                        </ul>
                        <ul>
                            <li>هل أنت مركز طبي؟</li>
                            <li>انضم الى دكتورز سبيس</li>
                        </ul>
                    </div>
                    <img src={process.env.PUBLIC_URL + '/assets/images/dr-logo.svg'} />
                </div>
                <p>حقوق النشر 2022 دكتور سبيس</p>
            </div>
        </div>
    )
}

export default Footer;