import './booking-doctor.scss';
import { useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from 'react';
import { message, Radio, Upload, Button,Checkbox, Modal } from 'antd';
import { getDoctorApi } from '../../data/api/search-api';
import { Link } from 'react-router-dom';
import { bookReservationApi } from '../../data/api/reservation-api';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Select from 'react-select'
import countryList from 'react-select-country-list'

const BookingDoctor = () => {
    const params = useParams();
    const { id } = params;
    const { timeId } = params;
    const [doctor, setDoctor] = useState()
    const [valueBookingName, setValueBookingName] = useState(1);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [fileId, setFileId] = useState('');
    const [loading, setLoading] = useState(false);
    const [isInsured, setIsInsured] = useState(0);
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [street, setStreet] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [postCode, setPostCode] = useState();
    const history = useHistory();
    const search = useLocation().search;
    const video = new URLSearchParams(search).get('video');
    const offerId = new URLSearchParams(search).get('offer_id');
    const [offer, setOffer] = useState('');
    const [value, setValue] = useState(1);

    const [country, setCountry] = useState('')
    const countries = useMemo(() => countryList().getData(), [])

    const changeCountry = value => {
        setCountry(value)
    }

    const upload = {
        name: 'file',
        maxCount: 1,
        action: `${process.env.REACT_APP_API_BASE_URL}/api/users/upload`,
        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                setFileId(info.file.response.path);
            } else if (info.file.status === 'error') {
                
            }
        },
        data: { mime_type:"image"}
    }

    

    const closeModalHandler = () => {
        setShowModal(false);
    }

    const onChangeBookingName = e => {
        setValueBookingName(e.target.value);
    };

    const onChange = e => {
        setValue(e.target.value);
    };

    useEffect(() => {
        localStorage.removeItem('previous-link')
        setOffer(JSON.parse(localStorage.getItem('offer')))
        getDoctor();
        window.scrollTo(0, 0)
    }, [])

    const getDoctor = () => {
        setLoading(true);
        getDoctorApi(id).then(
            res => {
                setDoctor(res.data.data)
            }
        )
    }

    const onSubmit = () => {
        let body = {}
        if (value != 1 && (!email || !firstName || !lastName || !street || !city || !state || !country.value || !postCode)) {
            return;
        }

        if (valueBookingName === 1) {
            body = {
                'for_other_person': 0,
                name: doctor.name,
                mobile: doctor.mobile,
                insured: isInsured,
                payment_method: value
            }

            if (body?.payment_method != 1) {
                body['customer']= {
                    email: email,
                    givenName: firstName,
                    surname: lastName
                };
                body['billing'] = {
                    street1: street,
                    city: city,
                    state: state,
                    country: country.value,
                    postcode: postCode
                }
            }

            if (offerId) {
                body['offer_id']=offerId
            }

            if (fileId) {
                body['attachment'] = fileId
            }

            setLoading(true);
            bookReservationApi(body, timeId).then(
                res => {
                    setLoading(false);
                    if (res.data.data?.payment_transaction?.checkout_id) {
                        history.push(`/payment-process/${res.data.data?.payment_transaction?.checkout_id}/${res.data.data?.payment_transaction?.payment_method_string}`);
                    } else {
                        // setShowModal(true);
                        history.push('/success-booking')
                    }
                }, error => {
                    message.error('حدث خطأ ما')
                }
            )
        } else {
            body = {
                name: name,
                mobile: mobile,
                for_other_person: 1,
                insured: isInsured,
                payment_method: value,
                customer: {
                    email: email,
                    givenName: firstName,
                    surname: lastName
                },
                billing: {
                    street1: street,
                    city: city,
                    state: state,
                    country: country.value,
                    postcode: postCode
                }
            }


            if (name && mobile) {
                setLoading(true);
                bookReservationApi(body, timeId).then(
                    res => {
                        setLoading(false);
                        if (res.data.data?.payment_transaction?.checkout_id) {
                            history.push(`/payment-process/${res.data.data?.payment_transaction?.checkout_id}/${res.data.data?.payment_transaction?.payment_method_string}`);
                        } else {
                            // setShowModal(true);
                            history.push('/success-booking')
                        }
                    }, error => {
                        message.error('حدث خطأ ما')
                    }
                )
            }
        }
    }

    const getCurrentDate = (separator='-') => {

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }

    return(
        <div className="booking-doctor">
            <h2>مراجعة وتأكيد الحجز</h2>
            <div className="doctor-content">
                <div className="element">
                    <div style={{'display': 'flex', 'border-bottom': '1px solid #EEE', 'paddingBottom': '20px', 'flexWrap': 'wrap'}}>
                        <div className="doctor-image">
                            <div className="images">
                                <img src={doctor?.image} alt="" />
                                <img src={doctor?.medical_center?.image} alt="" />
                            </div>
                        </div>
                        <div className="doctor-data" style={{'flexGrow': '1'}}>
                            <div className="doctor-name">
                                {doctor?.name ? <p>{doctor?.name}</p> : ''}
                            </div>
                            <div className="doctor-description">
                                {doctor?.description ? <p>{doctor?.description}</p> : '' }
                            </div>
                            <div className="doctor-description">
                                <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                                {doctor?.medical_specialties[0]?.name ? <p>{doctor?.medical_specialties[0]?.name}</p> : ''}
                            </div>
                            <div className="medical">
                                <p>إسم المركز الصحى</p>
                                <p>{doctor?.medical_center?.name}</p>
                            </div>
                        </div>
                    </div>

                    <div className="doctor-address">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/calendar.svg'} alt="" />
                            <p>{getCurrentDate()}</p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/money.svg'} alt="" />
                            {video == 'true' ? <p>موعد مع دكتور: {doctor?.video_fees} ر.س</p> : <p>موعد مع دكتور: {doctor?.clinic_fees} ر.س</p>}
                        </div>
                        {doctor?.address ? <div>
                            <a target="_blank" href={`https://maps.google.com/?q=${doctor.latitude},${doctor.longitude}`}>
                                <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                <p>{doctor.address}</p>
                            </a>
                        </div> : '' }
                    </div>
                </div>

                <div className="price">
                    <h3>مراجعة السعر</h3>
                    <div className="top-price">
                        <p>موعد مع دكتور</p>
                        <p style={{marginInlineStart: '5px'}}>{video == 'true' ? doctor?.video_fees : doctor?.clinic_fees} ر.س</p>
                    </div>
                    {offer?.id && <div className="top-price">
                        <p>السعر بعد الخصم</p>
                        <p style={{marginInlineStart: '5px'}}>{offer?.price_after_discount} ر.س</p>
                    </div>}
                    <div className="bottom-price">
                        <p>الإجمالى</p>
                        {!offer?.id && <p style={{marginInlineStart: '5px'}}>{video == 'true' ? doctor?.video_fees : doctor?.clinic_fees} ر.س</p>}
                        {offer?.id && <p style={{marginInlineStart: '5px'}}>{offer?.price_after_discount} ر.س</p>}
                    </div>
                </div>
            </div>
            <div className="booking-steps">
                <div className="steps">
                    <span className="circle"></span>
                    <span className="line"></span>
                    <span className="circle"></span>
                    <span className="line"></span>
                    <span className="circle"></span>
                    {/* <span className="line"></span>
                    <span className="circle"></span> */}
                </div>
                <div className="booking-data">
                    <div className="step-one">
                        <p className="step-title">بيانات الحجز</p>
                        <Radio.Group onChange={onChangeBookingName} value={valueBookingName}>
                            <Radio value={1}>الحجز بأسمك</Radio>
                            <Radio value={2}>الحجز بأسم شخص أخر</Radio>
                        </Radio.Group>
                        { valueBookingName === 1 ? <Upload {...upload}>
                                <Button>إرفاق الملف الطبى</Button>
                            </Upload> :
                                <div className="first-row">
                                    <div className="field">
                                        <label>الاسم بالكامل<span>*</span></label>
                                        <input type="text" name="" onInput={(event) => setName(event.target.value)} />
                                    </div>
                                    <div className="field">
                                        <label>رقم الجوال<span>*</span></label>
                                        <input type="text" style={{'direction': 'ltr', 'textAlign': 'right'}} name="" onInput={(event) => setMobile(event.target.value)} />
                                    </div>
                                </div>
                        }
                        <Checkbox onChange={(e) => setIsInsured(e.target.checked ? 1 : 0)}>مؤمن عليك</Checkbox>
                    </div>
                    <div className="step-two">
                        <p className="step-title">اختيار طريقة الدفع</p>
                        <p>برجاء اختيار طريقة الدفع</p>
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio value={1}>كاش</Radio>
                            {/* <Radio value={2}>فيزا</Radio>
                            <Radio value={3}>ماستر كارد</Radio>
                            <Radio value={4}>مدى</Radio>
                            <Radio value={5}>stc pay</Radio> */}
                        </Radio.Group>
                    </div>
                    {value != 1 ? <div className="step-three">
                        <p className="step-title">الدفع ببطاقة الائتمان</p>
                        <div className="payment">
                            <img src={process.env.PUBLIC_URL + '/assets/images/visa.svg'} alt="" />
                            <img src={process.env.PUBLIC_URL + '/assets/images/mastercard.svg'} alt="" />
                        </div>
                        <div className="first-row">
                            <div className="field">
                                <label>البريد الألكترونى<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="second-row">
                            <div className="field">
                                <label>الإسم الأول<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div className="field">
                                <label>الإسم الأخير<span>*</span></label>
                                <input type="text" onInput={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                        <div className="thrid-row">
                            <div className="field">
                                <label>الدولة<span>*</span></label>
                                <Select placeholder="" options={countries} value={country} onChange={changeCountry} />
                            </div>
                            <div className="field">
                                <label>المدينة<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setCity(e.target.value)} />
                            </div>
                            <div className="field">
                                <label>المنطقة<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setState(e.target.value)} />
                            </div>
                            <div className="field">
                                <label>الشارع<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setStreet(e.target.value)} />
                            </div>
                        </div>
                        <div className='fourth-row'>
                            <div className="field">
                                <label>الرقم البريدى<span>*</span></label>
                                <input type="text" name="" onInput={(e) => setPostCode(e.target.value)} />
                            </div>
                        </div>
                    </div> : ''}
                    <div className="step-four">
                        <button type="" onClick={onSubmit}>اتمام الحجز</button>
                    </div>
                </div>
            </div>

            {/* <Modal title="" visible={showModal} footer={null} closable={false} wrapClassName="success-modal" maskClosable={false} onCancel={closeModalHandler} width={600}>
                
            </Modal> */}

            <Backdrop className='backdrop' open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default BookingDoctor;