import axios from "./base-api";

export const login = (data) =>
  axios({
    method: "post",
    url: `/login`,
    data: data,
  });

export const register = (data) =>
  axios({
    method: "post",
    url: `/register`,
    data: data,
  });

export const verifyAccount = (data) =>
  axios({
    method: "post",
    url: `/verified-mobile`,
    data: data,
  });

export const getProfileApi = () => 
  axios({
    method: 'get',
    url: '/profile'
  })

export const updateProfileApi = (data) => 
  axios({
    method: 'post',
    url: 'profile',
    data: data
  })

export const changePasswordApi = (data) => 
  axios({
    method: 'post',
    url: 'change-password',
    data: data
  })

export const updateProfileInfoApi = (data) => 
  axios({
    method: 'post',
    url: 'create-profile',
    data: data
  })

export const forgetPasswordEmailApi = (data) => 
  axios({
    method: 'post',
    url: 'forget-password',
    data: data
  })

export const forgetPasswordMobileApi = (data) => 
  axios({
    method: 'post',
    url: 'forget',
    data: data
  })

export const resetPasswordMobileApi = (data) => 
  axios({
    method: 'post',
    url: 'reset',
    data: data
  })