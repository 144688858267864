import './doctors-result.scss';
import { Skeleton, Empty } from "antd";
import { Rate } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { searchApi } from '../../../../data/api/search-api';
import { useState, useEffect } from 'react';

const DoctorsResult = ({medicalCenter}) => {
    const history = useHistory();
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDoctors();
    }, [medicalCenter])

    const getDoctors = () => {
        setLoading(true);
        const body = {
            m_center_id: medicalCenter?.id
        }
        searchApi(body).then(
            res => {
                setLoading(false);
                console.log(res.data.data);
                setDoctors(res.data.data)
            }
        )
    }

    const navigateToDoctor = (id) => {
        history.push(`/doctor/${id}`)
    }

    return(
        <div className="doctors-result">
            <div className="">
                <Skeleton className="" active={true} loading={loading} avatar>
                    {doctors.length == 0 ? (
                        <div className="">
                            <Empty
                                description={
                                <span className="font-Lsmall weight-600 text-theme-color">
                                    لا يوجد أطباء
                                </span>
                                }
                            />
                        </div>
                    ) : (
                        doctors.map((doctor) => (
                            <div key={doctor.id} className="element" onClick={() => navigateToDoctor(doctor.uuid)}>
                                <div className="doctor-image">
                                    <div className="images">
                                        <img className="doctor-image-element" src={doctor.image} alt={doctor.name} />
                                        {doctor?.medical_center?.image ? <img className="medical-center-image" src={doctor?.medical_center?.image} alt="" /> : ''}
                                    </div>
                                </div>
                                <div className="doctor-data">
                                    <div className="doctor-name">
                                        <p>{doctor.name}</p>
                                        {/* {doctor.clinic_allow ? <img src={process.env.PUBLIC_URL + '/assets/images/audio-call.svg'} alt="" /> : ''} */}
                                        {doctor.video_allow ? <img src={process.env.PUBLIC_URL + '/assets/images/video-call.svg'} alt="" /> : ''}
                                    </div>
                                    { doctor?.description ?<div className="doctor-description">
                                        <p>{doctor?.description}</p>
                                    </div> : ''}
                                    {doctor?.medical_specialties[0]?.name ? <div className="doctor-description">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                                        <p>{doctor?.medical_specialties[0]?.name}</p>
                                    </div> : ''}
                                    {doctor?.medical_center?.name ? <div className="medical">
                                        <p>إسم المركز الصحى</p>
                                        <p>{doctor?.medical_center?.name}</p>
                                    </div> : ''}
                                </div>
                                <div className="doctor-rate">
                                    {/* <div className="rate">
                                        <Rate allowHalf disabled defaultValue={5} />
                                    </div> */}
                                    <div className="price">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/money.svg'} alt="" />
                                        <p>سعر الكشف {doctor.clinic_fees} ر.س</p>
                                    </div>
                                    <div className="price" style={{"display": "flex", "flexWrap": "nowrap"}}>
                                        <a target="_blank" href={`https://maps.google.com/?q=${doctor.latitude},${doctor.longitude}`}>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                            <p>{doctor.address}</p>
                                        </a>
                                    </div>
                                    <div className="booking">
                                        <Link to={`/doctor/${doctor.uuid}`}>أحجز</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </Skeleton>
                
            </div>

        </div>
    )
}

export default DoctorsResult;