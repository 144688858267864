import './terms-conditions.scss'; 

const TermsConditions = () => {
    return (
        <div className="terms-container">
                <h3>سياسة المستخدم والخصوصية</h3>
                    <h5>1. العلاقة التعاقدية</h5>
                    <span>1.1 تشكل البنود والشروط اتفاقية بينك كفرد ، (المستخدم) من داخل المملكة العربية السعودية  وجميع المناطق الجغرافية الأخرى التي تخضع فيها دكتورز سبيس لأنشطة تجارية ، من تطبيقات الهاتف المحمول ، والمواقع الإلكترونية ، والمحتوى ، والمنتجات والخدمات التي توفرها شركة دكتورزسبيس السعودية للإتصالات وتكنولوجيا المعلومات SPC المسجلة في المملكة العربية السعودية بموجب السجل التجاري رقم. 1010934231 ، المالك والمشغل لموقع وتطبيق دكتورز سبيس  (نحن) .</span>
                    <span>1.2 يشكل وصولك إلى الخدمات واستخدامك لها موافقتك على الالتزام بهذه الشروط ، مما ينشئ علاقة تعاقدية بينك وبين دكتورز سبيس باستخدام الخدمات ، فإنك توافق على الالتزام بهذه الشروط ويجب أن تحدد مربع "أوافق" أدناه موضحًا موافقتك على هذه الشروط .</span>
                    <span>1.3 باستخدام الخدمات ، أنت توافق على أن لديك القدرة الكاملة للدخول في اتفاقية ملزمة قانونًا ، وأن تكون مسؤولاً في حالة انتهاكك للشروط. بخلاف ذلك ، فأنت تدرك أن دكتورز سبيس قد تنهي أو تعوق استخدامك للخدمات.</span>
                    <span>1.4 أنت تدرك وتقر بأنه يجوز لدكتورز سبيس تعديل الشروط المتعلقة بالخدمات من وقت لآخر. يخطرك دكتورز سبيس بهذه التغييرات على الشروط وموافقتك على الشروط المعدلة مطلوبة لمواصلة استخدام الخدمات.</span>
                    <span>1.5 يوافق المستخدمون على أنه يمكن لـ دكتورز سبيس الاتصال بهم عن طريق الرسائل النصية أو المكالمات الهاتفية على أرقام الهواتف التي يقدمونها عند تسجيلات المستخدم بما في ذلك لأغراض التسويق الداخلي لـ دكتورز سبيس. لن يشكل هذا إفصاحًا أو مشاركة لأي من معلومات التعريف الشخصية الخاصة بك.</span>
                    <span>1.6 من خلال الوصول إلى أي جزء من الخدمات أو استخدامه ، فإنك توافق على أنك قد قرأت وفهمت ووافقت على الالتزام بهذه الشروط ، بالإضافة إلى أي إرشادات أو سياسات أو قواعد إضافية متاحة حسب الاقتضاء على دكتورز سبيس وخدماته ، بما في ذلك على سبيل المثال لا الحصر ، سياسة خصوصية دكتورز سبيس وسياسة خصوصية الطبيب. تم دمج هذه السياسات والإرشادات هنا بالرجوع إليها في الشروط.
إذا كنت لا توافق على الالتزام بهذه الشروط وعلى اتباع جميع القوانين والمبادئ التوجيهية والسياسات المعمول بها ، فلا تدخل إلى الخدمات أو تستخدمها.
</span>
                    <h5>2. الخدمات</h5>
                    <span>2.1 يشكل هذا منصة تقنية تتيح لمستخدمي تطبيق دكتورز سبيس للهاتف المحمول أو موقع الويب البحث عن المواعيد وحجزها ، فضلاً عن طلب الاستشارة الطبية عن بُعد والتشخيص من خلال دكتورز سبيس مع مقدمي الرعاية الصحية المستقلين مثل الأطباء في العيادات الفردية والعيادات الشاملة والمستشفيات والمختبرات والفحوصات ، والاتصال بالصيدليات المحلية للحصول على الأدوية وطلبات الأدوية (يُشار إليها معًا باسم "المزود").
نحن لا نقدم المشورة الطبية أو التشخيص أو العلاج. نحن لا نصادق ولا نوصي بأنك مقدم خدمة محدد. نحن لا - ولا ننوي - ممارسة الطب. نحن نساعدك فقط في البحث والحجز والاتصال بالموفر الأكثر ملاءمة وفقًا لاحتياجاتك الطبية. لذلك ، من الأهمية بمكان أن تستخدم نفس الحكمة والحصافة التي ستعمل بها دون استخدام خدماتنا.
</span>
                    <h5>3. الترخيص والملكية الفكرية</h5>
                    <span>3.1 جميع التراخيص والممتلكات الفكرية بما في ذلك على سبيل المثال لا الحصر العلامات التجارية والشعار مملوكة حصريًا لـ دكتورز سبيس . لا يجوز لك تحمل أي عمل قد يشكل إساءة استخدام أو انتهاكًا لحقوق الطبع والنشر لـ دكتورز سبيس ، بما في ذلك التسويق الكاذب أو الإعلانات المضللة ، أو نسخة من تراخيص دكتورز سبيس أو العلامة التجارية تحت أي ظرف من الظروف. باستخدام خدماتنا ، تمنحك دكتورز سبيس بموجب هذا حقًا محدودًا وقابل للإلغاء وغير حصري وغير قابل للترخيص الداخلي ، الحق في: (1) الوصول إلى تطبيق الجوال أو موقع الويب الخاص بنا وتحميله واستخدامه لغرض وحيد هو استخدام خدماتنا ؛ و (2) الوصول إلى واستخدام المعلومات والمواد المقدمة أو المتوفرة أو الموجودة بطريقة أخرى في المحتوى الخاص بنا.</span>
                    <span>3.2 هذا الترخيص هو ؛</span>
                    <span>- لا يُقصد به التنازل عنها  أو بيعها أو تأجيرها أو تصديرها أو استيرادها أو توزيعها أو نقلها أو منحها لأطراف أخرى.</span>
                    <span>- غير مخصص للاستخدام التجاري. أنت مرخص فقط لاستخدام خدماتنا للاستخدام الشخصي غير المربح وغير التجاري.</span>
                    <h5>4. تقديم الخدمات</h5>
                    <span>4.1 أنت تقر بأن بعض الخدمات يتم توفيرها تحت أسماء مختلفة يشار إليها حاليًا باسم "الحجوزات" و "العروض" . أنت تقر أيضًا بإمكانية إتاحة الخدمات بموجب (1) بعض الشركات الفرعية والتابعة لـ دكتورز سبيس أو (2) مقدمي الخدمة المستقلين.</span>
                    <h5>5. وصف الخدمات</h5>
                    <span>- الحجوزات: حجز المواعيد مع الأطباء في العيادات الفردية والمستوصفات والمستشفيات والمختبرات والفحوصات.</span>
                    <span>- عروض: حجز الخدمات الطبية مع مختلف مقدمي الخدمات بأسعار مخفضة.</span>
                    <span>-الاستشارة / الاستشارة عن بعد: الممارسة الطبية عن بعد والاستشارة باستخدام تكنولوجيا الاتصالات والمعلومات مع مقدم الرعاية الصحية.</span>
                    <h5>6. المحتوى الخاص بنا</h5>
                    <span>6.1 جميع النصوص والبيانات والرسومات والصور والصور الفوتوغرافية والفيديو والصوت والمعلومات والاقتراحات والإرشادات والمواد الأخرى المقدمة أو المتوفرة  بطريقة أخرى في خدماتنا هي جزء من حقوق الملكية الفكرية لـ دكتورز سبيس</span>
                    <span>6.2 يجوز لك طباعة المعلومات وتنزيلها وتخزينها من المحتوى الخاص بنا في الوقت الذي يناسبك ، ولكن دكتورز سبيس لا يمنحك أي حقوق تتعلق بحقوق الملكية الفكرية الخاصة به.</span>
                    <h5>7. حقوق الملكية الفكرية للأطراف الثالثة</h5>
                    <span>7.1 قد يحتوي النظام الأساسي لدكتورز سبيس على روابط إلى مواقع ويب أو معلنين أو خدمات لأطراف ثالثة ليست مملوكة لـ دكتورز سبيس. أنت تقر وتفهم أن مثل هذا المحتوى قد يخضع لشروط استخدام وسياسات خصوصية مختلفة عن دكتورز سبيس . لذلك ، لا تنطبق الشروط الحالية عليك عند الوصول إلى خدمات الجهات الخارجية أو استخدامها.</span>
                    <span>7.2 إذا قمت بالوصول إلى مواقع الويب الخاصة بأطراف ثالثة من موقع ويب دكتورز سبيس ، فأنت تفعل ذلك على مسؤوليتك الخاصة وتوافق على إعفاء دكتورز سبيس من أي وجميع المسؤوليات الناشئة عن هذا الاستخدام.</span>
                    <h5>8. استخدام الخدمات</h5>
                    <span>8.1 حسابات المستخدمين</span>
                    <span>8.1.1 لإنشاء حساب مستخدم ، سوف ترسل إلى دكتورز سبيس المعلومات الشخصية ، مثل اسمك وعنوانك ورقم هاتفك المحمول والبريد الإلكتروني. أنت توافق على منح دكتورز سبيس الحق في مشاركة هذه المعلومات الشخصية مع مزودي الخدمة المسجلين لدينا وفقًا لاتفاقنا التعاقدي معهم ؛</span>
                    <span>- يجب أن تكون بياناتك الشخصية دقيقة وكاملة ومحدثة في جميع الأوقات. في حالة عدم القيام بذلك ، فإنك تقر بأن دكتورز سبيس له الحق في إنهاء أو إعاقة استخدامك للخدمات.</span>
                    <span>- يجب عليك اختيار كلمة مرور للوصول إلى حساب المستخدم الخاص بك ويجب أن تحافظ على سريتها في جميع الأوقات.</span>
                    <span>- أنت تدرك أنك ستكون مسؤولاً عن أي إجراءات / أنشطة تتم على حساب المستخدم الخاص بك من قبل أطراف ثالثة غير مصرح لها. يجب عليك إخطارنا إذا كنت تعتقد بشدة أن حسابك قد تم اختراقه.</span>
                    <span>- لا يجوز لك تحت أي ظرف من الظروف الرد على طلب للحصول على كلمة المرور الخاصة بك ، وخاصةً طلب من شخص يدعي أنه موظف في دكتورز سبيس.</span>
                    <span>- لا يجوز لك تفويض أو تعيين أو نقل حساب المستخدم الخاص بك إلى أطراف ثالثة.</span>
                    <span>- أنت تدرك أنه سيتم رفض وصولك إلى حسابك إذا فشلت في إدخال كلمة مرورك مرتين متتاليتين.</span>
                    <span>- أنت توافق وتقر على الخدمات التي تقدمها دكتورز سبيس بما في ذلك الاستشارة عن بعد وتوافق على التوقيع على نموذج الموافقة المتعلق بهذه الخدمات عند تقديمها.</span>
                    <span>8.1.2 أنت توافق على تزويد دكتورز سبيس بالمعلومات الأساسية المطلوبة من أجل استخدام الخدمات. يجب أن تكون هذه المعلومات الشخصية خاصة وسرية وتخضع لسياسة خصوصية دكتورز سبيس بموجب القانون للحفاظ على خصوصية وأمن المعلومات الصحية المحمية للمستخدمين. تعتبر دكتورز سبيس والشركات التابعة لها أن خصوصية المعلومات الصحية هي أحد أهم العناصر في العلاقة مع المستخدمين. مسؤولية دكتورز سبيس للحفاظ على سرية المعلومات الصحية هي مسؤولية يأخذها دكتورز سبيس على محمل الجد.</span>
                    <span>8.1.3 من خلال الوصول إلى الخدمات واستخدامها ، فإنك توافق على استخدام عنوان بريدك الإلكتروني لإرسال إشعارات متعلقة بالخدمة أو تغيير الميزات أو العروض الخاصة بما في ذلك أي إشعارات مطلوبة بموجب القانون ، بدلاً من الاتصال بالبريد العادي.</span>
                    <span>8.2 متطلبات المستخدم</span>
                    <span>8.2.1 أنت مؤهل فقط للوصول إلى خدماتنا واستخدامها إذا كان عمرك 18 عامًا على الأقل.</span>
                    <h5>لن يتم مساءلة  دكتورز سبيس من قبل المستخدمين القاصرين (أقل من 18 عامًا من العمر).</h5>
                    <span>8.3 المحتوى المقدم من المستخدم</span>
                    <span>8.3.1 تحتفظ بالملكية الكاملة للمحتوى الذي تقوم بتحميله أو إرساله أو تخزينه أو إرساله أو تلقيه إلى خدماتنا ولديك الحق المطلق في نشره. أنت تقر أيضًا أنه في هذه العملية ، تمنح أيضًا دكتورز سبيس والشركات التابعة لها ترخيصًا عالميًا لاستخدام مثل هذا المحتوى واستضافته وتخزينه وإعادة إنتاجه وتعديله وإنشاء أعمال مشتقة منه والتواصل والنشر والأداء العام وعرضه وتوزيعه بشكل عام. الحقوق التي تمنحها دكتورز سبيس هي لغرض محدود وهو تشغيل خدماتنا وتعزيزها وتحسينها وتطوير خدمات جديدة. تظل هذه الحقوق سارية حتى بعد توقفك عن استخدام خدماتنا.</span>
                    <h5>9. التقييمات</h5>
                    <span>9.1 تتيح لك خدماتنا مشاركة التعليقات والتعليقات والمراجعات والآراء والتوصيات (يُشار إليها معًا باسم "التقييمات") فيما يتعلق بجودة الخدمات التي يوفرها دكتورز سبيس وجودة الخدمة المقدمة لك من قبل مقدمي الخدمة.</span>
                    <span>9.2 المراجعات والتوصيات والتعليقات التي أدلى بها المستخدمون الآخرون ، لا تشارك دكتورز سبيس أو تؤيد أو توصي بمثل هذه التقييمات ، وليس لها سلطة الإشراف أو التوجيه أو التحكم في مثل هذه التقييمات.</span>
                    <span>9.3 يجوز لـ دكتورز سبيس - ولكن ليس ملزمًا - مراقبة أو إزالة تعليقات المستخدمين وتقييماتهم وفقًا لتقديرها الخاص ، في أي وقت وبدون إشعارات أخرى للمستخدمين.</span>
                    <span>9.4 من المهم ملاحظة أن التقييمات هي حسابات شخصية مباشرة للمستخدمين الفرديين ولا تشكل حكم مقدم الخدمة ولا نتائج العلوم الطبية. يجب أن تفهم أن التقييمات الواردة فيها هي من أولئك الذين يقدمون نفس الشيء ، وأن هذه التقييمات لا تعكس آراء دكتورز سبيس ولا تشكل بأي حال من الأحوال تأييدنا أو توصيتنا لها. لذلك ، لن يتحمل دكتورز سبيس أي مسؤولية عن أي من التقييمات المنشورة. علاوة على ذلك ، يجب أن تضع في اعتبارك أن التقييمات عرضة للأخطاء والتحيزات الشائعة في الحسابات الشخصية المباشرة ولا ينبغي افتراض أنها موثوقة أو خالية من الأخطاء.</span>
                    <h5>10. الدفع</h5>
                    <span>10.1 يمكنك اختيار الدفع من خلال أي طرق دفع متاحة بما في ذلك بطاقة الائتمان وبطاقة الخصم ومدى والمحافظ الرقمية عبر منصتنا. ستكون المدفوعات شاملة الضرائب المطبقة حيثما يقتضي القانون ذلك.</span>
                    <span>10.2 تحتفظ دكتورز سبيس بالحق في إنشاء و / أو إزالة و / أو مراجعة الرسوم في أي وقت وفقًا لتقديرها الخاص. قد تقدم دكتورز سبيس من وقت لآخر عروضًا ترويجية وخصومات لمستخدمين معينين قد ينتج عنها مبالغ مختلفة يتم تحصيلها مقابل الخدمات.</span>
                    <span>10.3 فيما يتعلق بمقدمي الطرف الثالث (TPP) ، ستكون المدفوعات مستحقة مباشرة لمقدمي الطرف الثالث ، وستقوم دكتورز سبيس بتحصيل مدفوعات هذه الرسوم منك ، لذلك قد يحتاج المستخدم إلى الالتزام بشروط الدفع الخاصة بـ TPP.</span>
                    <h5>11. سياسة الاسترداد</h5>
                    <span>11.1 الرسوم التي تدفعها نهائية وغير قابلة للاسترداد ، ما لم تحدد دكتورز سبيس خلافًا لذلك.</span>
                    <span>11.2 سيتم رد الأموال بنفس طريقة الدفع ، إن وجدت.</span>
                    <span>11.3 تحتفظ دكتورز سبيس بحقها في رد أي مبالغ إلى حساب المستخدمين لاستخدامها في خدمات أخرى وفقًا لتقديرها الخاص.
لن تكون دكتورز سبيس مسؤولة ولن تضمن مستوى معينًا من الرضا الطبي ولن تكون مسؤولة عن أي استشارات غير راضية يقدمها المزود.
لا يحق للمستخدمين استرداد رسوم الاستشارة وأي مصروفات تتم على طول الطريق في حال لم تكن راضيًا عن الخبرة الطبية أو مقدم الخدمة.
</span>
                    <h5>12. إخلاء المسؤولية وتحديد الأهلية</h5>
                    <span>12.1 على الرغم من أننا نستخدم التدابير المتعلقة بالتحقق من مقدمي الخدمات (يجب على جميع مقدمي الخدمة تقديم التراخيص والتصاريح والشهادات المطلوبة بموجب القانون لممارسة الطب) ، لا يضمن دكتورز سبيس أن يكون محتواه دائمًا خاليًا من الأخطاء أو كاملًا أو دقيقًا أو محدث.</span>
                    <span>12.2 لن تتحمل دكتورز سبيس أي مسؤولية عن الأضرار التي تلحق بك بما في ذلك على سبيل المثال لا الحصر ، الأضرار المباشرة أو غير المباشرة أو الخاصة أو التبعية أو العقابية أو العرضية ، الناشئة عن أو فيما يتعلق بأي من الأفعال التالية التي يرتكبها مقدمو الخدمة:</span>
                    <span>- عدم الالتزام بالآداب العامة ؛</span>
                    <span>- السلوك غير المهني أو غير الأخلاقي أو المخزي ؛</span>
                    <span>- التشخيص الخاطئ أو المتأخر لحالة طبية ؛</span>
                    <span>- العلاج غير الصحيح ، وتأخير العلاج ، أو عدم العلاج على الإطلاق ؛</span>
                    <span>- سوء التصرف الطبي أو الإهمال ؛</span>
                    <span>- إلغاء الموعد أو تأخر المزود أو عدم الحضور على الإطلاق في موعد الفحص.
أنت تدرك وتقر بأن دكتورز سبيس  لن تكون مسؤولة عن أي أضرار أو وفيات أو إصابات أو أمراض متعاقد عليها أو خسائر أو تكاليف أو مصروفات مهما كانت طبيعتها الناجمة عن مقدم الخدمة.
</span>
                    <h5>13. القوة القاهرة</h5>
                    <span>13.1 لن تكون دكتورز سبيس مسؤولة عن الإخفاق في أداء التزاماتها بموجب هذه الشروط بسبب حدوث أي حدث خارج عن سيطرتها المعقولة ، بما في ذلك ، على سبيل المثال لا الحصر ، الحرائق ، والإرهاب ، والكوارث الطبيعية ، والأوبئة ، والحرب ، والمقاطعة ، واضطراب العمل أو انقطاع الإنترنت أو الاتصالات أو انقطاع الخدمة أو أي حالات أخرى لا يمكن توقعها أو منعها أو السيطرة عليها.</span>
                    <h5>14. تحديد المسؤولية</h5>
                    <span>14.1 إلى الحد الذي يسمح به القانون ، لن تتحمل دكتورز سبيس أو مسؤوليها أو مديريها أو موظفيها أو وكلائها بأي حال من الأحوال المسؤولية تجاهك عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو خاصة أو عقابية أو تبعية على الإطلاق ، الناتجة عن أو فيما يتعلق بأي (1) أخطاء أو عدم دقة في المحتوى ، (2) إصابة شخصية أو ضرر من أي نوع كان ، ناتجًا عن وصولك إلى خدماتنا واستخدامها ، (3) أي غير مصرح به الوصول إلى أو استخدام خوادمنا الآمنة و / أو أي وجميع المعلومات الشخصية و / أو المعلومات المالية المخزنة فيها ، (4) أي انقطاع أو توقف للإرسال إلى أو من خدماتنا ، (4) أي أخطاء أو فيروسات أو أحصنة طروادة ، أو ما شابه ، والتي قد يتم نقلها إلى أو من خلال خدماتنا من قبل أي طرف ثالث ، (5) أي أخطاء طبية أو تشخيصية أو إغفالات أو وصفات طبية في أي محتوى أو عن أي خسارة أو ضرر من أي نوع يحدث نتيجة لذلك لاستخدامك لأي محتوى تم نشره أو إرساله بالبريد الإلكتروني أو نقله ، أو تم توفيرها بطريقة أخرى عبر الخدمات ، سواء استنادًا إلى ضمان أو عقد أو ضرر أو أي نظرية قانونية أخرى ، وما إذا كان الموفر قد حذر من احتمال حدوث مثل هذه الأضرار أم لا. يسري تحديد المسؤولية السابق ذكره إلى أقصى حد يسمح به القانون.</span>
                    <span>14.2 أنت تقر تحديدًا بأن دكتورز سبيس لن تكون مسؤولة عن السلوك التشهيري أو العدواني أو غير القانوني لأي طرف ثالث وأن المخاطر أو الأذى أو الضرر الناجم عن ما سبق يقع عليك بالكامل.</span>
                    <span>14.3 علاوة على ذلك ، يتم التحكم في هذه الخدمات وتقديمها بواسطة دكتورز سبيس من منشآتها. لا تقدم دكتورز سبيس أية إقرارات بأن الخدمة مناسبة أو متاحة للاستخدام في خدمات المواقع الأخرى. أولئك الذين يصلون إلى الخدمة أو يستخدمونها من ولايات قضائية أخرى يفعلون ذلك بمحض إرادتهم ويكونون مسؤولين عن الامتثال لقانونهم المحلي.</span>
                    <h5>15. القانون الحاكم</h5>
                    <span>15.1 تخضع هذه الشروط لقوانين المملكة العربية السعودية. أي نزاع ينشأ فيما يتعلق بهذه الشروط ، يجب تسويته وسماعه أمام المحاكم المختصة في الرياض ، المملكة العربية السعودية.</span>
                    <h5>16. الامتثال للقوانين المحلية</h5>
                    <span>16.1 يعتمد تطبيق الخدمات واستخدامها على امتثالها للقوانين واللوائح المعمول بها في الموقع الذي تُستخدم فيه الخدمات.</span>
                    <span>16.2 تحتفظ دكتورز سبيس بالحق - وفقًا لتقديرها وحدها - في رفع إجراءات النزاع أمام أي محكمة تنطبق سلطتها القضائية على المستخدم المعني.</span>
            </div>
    );
}

export default TermsConditions;