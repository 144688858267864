import { useState, useRef } from 'react';
import { contactUsApi } from '../../../../data/api/global-api';
import './contact-us.scss';
import { message } from 'antd';

const ContactUs = () => {
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const firstName = useRef();
    const lastName = useRef();
    const email = useRef();
    const description = useRef();

    const checkFirstNameError = (e) => {
        if (e.target?.value.trim()) {
            setFirstNameError(false);
        } else{
            setFirstNameError(true);
        }
    }

    const checkLastNameError = (e) => {
        if (e.target?.value.trim()) {
            setLastNameError(false);
        } else{
            setLastNameError(true);
        }
    }

    const checkEmailError = (e) => {
        if (e.target?.value.trim()) {
            setEmailError(false);
        } else{
            setEmailError(true);
        }
    }

    const checkMessageError = (e) => {
        if (e.target?.value.trim()) {
            setDescriptionError(false);
        } else{
            setDescriptionError(true);
        }
    }

    const handelSubmit = () => {
        if (!firstName.current.value.trim()) {
            setFirstNameError(true);
        } else {
            setFirstNameError(false);
        }
        if (!lastName.current.value.trim()) {
            setLastNameError(true);
        } else {
            setLastNameError(false);
        }
        if (!email.current.value.trim()) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        if (!description.current.value.trim()) {
            setDescriptionError(true);
            return;
        } else {
            setDescriptionError(false);
        }

        const body = {
            name: firstName.current.value + ' ' + lastName.current.value,
            email: email.current.value,
            message: description.current.value
        }

        contactUsApi(body).then(
            res => {
                firstName.current.value = '';
                lastName.current.value = '';
                email.current.value = '';
                description.current.value = '';
                message.success('تم إرسال رسالتك بنجاح');
            }
        ).catch(error => {
            message.error(Object.values(error.response.data?.errors)[0][0] || 'حدث خطأ ما');
        })
    }

    return(
        <div className="contact-us" id="contactUs">
            <div className="contact-header">
                <h4>تواصل معنا</h4>
            </div>
            <div className="contact-data">
                <div className="right">
                    <div className="element">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/contact-address.svg'} alt="" />
                        </div>
                        <p>السعودية - الرياض - حي الصحافة ، طريق الإمام 
سعود بن فيصل</p>
                    </div>
                    <div className="element">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/contact-phone.svg'} alt="" />
                        </div>
                        <p style={{direction: 'ltr'}}>920035053</p>
                    </div>
                    <div className="element">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/contact-fax.svg'} alt="" />
                        </div>
                        <p style={{direction: 'ltr'}}>8001111052</p>
                    </div>
                    <div className="element">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/contact-email.svg'} alt="" />
                        </div>
                        <p>support@drs-space.sa</p>
                    </div>
                    <div className='social-links'>
                        <h3>أو تابعنا على</h3>
                        <div>
                            <a href='https://www.linkedin.com/company/drs-space/about/' target='_blank'><img src={process.env.PUBLIC_URL + '/assets/images/linkedin.png'} /></a>
                            <a href='https://twitter.com/drspace_sa' target='_blank'><img src={process.env.PUBLIC_URL + '/assets/images/twitter.png'} /></a>
                            <a href='https://www.instagram.com/drs.space/' target='_blank'><img src={process.env.PUBLIC_URL + '/assets/images/instagram.svg'} /></a>
                            <a href='https://www.facebook.com/Drs.spcae' target='_blank'><img src={process.env.PUBLIC_URL + '/assets/images/facebook.svg'} /></a>
                        </div>
                    </div>
                </div>
                <div className="left">
                    <div>
                        <div>
                            <input ref={firstName} type="text" name="" placeholder="الاسم الاول" onInput={checkFirstNameError} />
                            <span className="error">{firstNameError &&'هذا الحقل مطلوب'}</span>
                        </div>
                        <div>
                            <input ref={lastName} type="text" name="" placeholder="الاسم الأخير" onInput={checkLastNameError} />
                            <span className="error">{lastNameError &&'هذا الحقل مطلوب'}</span>
                        </div>
                    </div>
                    <input ref={email} type="email" name="" placeholder="البريد الألكترونى" onInput={checkEmailError} />
                    <span className="error">{emailError &&'هذا الحقل مطلوب'}</span>
                    <textarea ref={description} rows="6" cols="" placeholder="الرسالة" onInput={checkMessageError}></textarea>
                    <span className="error">{descriptionError &&'هذا الحقل مطلوب'}</span>
                    <button onClick={handelSubmit} type="">إرسال</button>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;