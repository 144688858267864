import { useParams } from "react-router-dom";
import './lab-details.scss';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Skeleton, Tooltip } from "antd";

const LabDetails = ({doctors, offerId}) => {
    const params = useParams();
    const { id } = params;
    const history = useHistory();
    const [lab, setLab] = useState('');
    const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [savedAnalysis, setSavedAnalysis]= useState([]);
    const [test, setTest] = useState(false);
    
    useEffect(() => {
        setLab(() => JSON.parse(localStorage.getItem('lab')));
        checkAddedAnalysis();
    }, [doctors]);

    const checkAddedAnalysis = () => {
        if (doctors?.labSpecialties.length && lab?.analysis?.length) {
            lab.analysis?.forEach(item => {
                doctors.labSpecialties.forEach(element => {
                    if (element.id === item.id ) {
                        setSavedAnalysis((pre) => [...pre, element]);
                        element.is_included = true;
                    }
                })
                console.log(savedAnalysis);
            })
            checkButtonIsDisabled();
        }
    }

    const checkButtonIsDisabled = () => {
        if (doctors.labSpecialties?.some(item => item.is_included)) {
            setButtonIsDisabled(true);
        } else {
            setButtonIsDisabled(false);
        }
    }

    const makeOrder = () => {
        localStorage.setItem('savedAnalysis', JSON.stringify(savedAnalysis));
        localStorage.removeItem('offer');
        localStorage.setItem('offerId', offerId)
        history.push(`/booking-cart/${id}`)
    }

    const addAnalysis = (element, index) => {
        let checkArray = [];
        checkArray = savedAnalysis.map(item => item.id === element.id);
        if (!checkArray) {
            if (checkArray.includes(true)) {
                element.is_included = false;
                setSavedAnalysis((pre) => pre.splice(index, 1));
            } else {
                element.is_included = true;
                setSavedAnalysis((pre) => [...pre, element]);
            }
        } else {
            if (checkArray.includes(true)) {
                element.is_included = false;
                setSavedAnalysis((pre) => pre.splice(index, 1));
            } else {
                element.is_included = true;
                setSavedAnalysis((pre) => [...pre, element]);
            }
        }
        setTest((pre) => !pre);
    }

    const showAllText = (elementId) => {
        document.querySelector(`#element-${elementId}`).classList.add('active')
    }


    return(
        <Skeleton active={true} loading={loading} avatar>
            <div className="lab-details-container">
                <div className="lab-header">
                    <p>التحاليل</p>
                </div>
                {doctors?.labSpecialties.length ? 
                        <div className="vaccine-container">{
                            doctors?.labAnalysis.map((element, index) => (
                                <div key={index} className={element.is_included ? 'booked vaccine-card' : 'vaccine-card'}>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/lab-image.svg'} alt='' />
                                    <div className="vaccine-data" id={`element-${element.id}`}>
                                        <h3>{element?.labSpecialty?.name}</h3>
                                        {element?.offer?.id
                                            ? 
                                            <span>{element?.offer?.price_after_discount} ريال سعودى</span>
                                            : 
                                            <span>{element?.price} ريال سعودى</span>
                                        }
                                        
                                        <div className="show-more">
                                            {element?.note ? <span className={element.active ? 'note active' : "note"}>{element?.note}</span>: ''}
                                            <img onClick={() => showAllText(element.id)} className={element.active ? 'active' : ""} src={process.env.PUBLIC_URL + '/assets/images/show-more.svg'} />
                                        </div>
                                    </div>
                                    {!element.is_included ? 
                                        <button onClick={() => addAnalysis(element, index)}>+  إضافة</button>
                                    :   
                                        <button className="delete-vaccine" onClick={() => addAnalysis(element, index)}>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/delete.svg'} />
                                            <span>إزالة</span>
                                        </button>
                                    }
                                </div>  
                            ))
                            }
                        </div>
                    : 
                    ''
                }
                {doctors?.labSpecialties.length && savedAnalysis.length ? <div onClick={makeOrder} className="book-vaccine"><button>تأكيد الحجز</button></div> : ''}
            </div>
        </Skeleton>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(LabDetails);