import './offer-details.scss';

import { useState, useEffect } from 'react';
import { Skeleton } from 'antd';
import { useParams } from "react-router-dom";
import { getOfferDetailsAPi } from '../../data/api/offers-api';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Modal } from 'antd';
import Login from '../auth/page/login/login';
import moment from 'moment';
import AppointmentSlider from '../appointment-slider/appointment-slider'

const OfferDetails = ({user}) => {
    const history = useHistory();

    const params = useParams();
    const { id } = params;
    const [loading, setLoading] = useState(false);
    const [offer, setOffer] = useState();
    const [appointmentsBody, setAppointmentsBody] = useState({});
    const [dayIndex, setDayIndex] = useState(0);
    const [appointments, setAppointments ] = useState([]);
    const [selectedTime, setSelectedTime] = useState();
    const [showModal, setShowModal] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const search = useLocation().search;
    const video = new URLSearchParams(search).get('video');

    useEffect(() => {
        setLoading(true);
        getOfferDetailsAPi(id).then(
            res => {
                setOffer(res.data.data);
                const appointmentArray = res?.data?.data?.appointments.filter(element => element.allow_use)
                setAppointments(appointmentArray)
                const result = appointmentArray.reduce(function (r, a) {
                    r[a.day] = r[a.day] || [];
                    r[a.day].push(a);
                    return r;
                }, Object.create(null));
                const resultKeys = Object.keys(result).sort();
                const resultSorted = {}
                resultKeys.forEach(key => {
                    resultSorted[key] = result[key];
                })
                setAppointmentsBody(resultSorted);
                setLoading(false)
            }
        )
    }, [])

    const getNextDayAppointments = () => {
        if (dayIndex !== Object.keys(appointmentsBody).length -1) {
            const counter = dayIndex + 1;
            setDayIndex(counter);
        }
    }

    const getPrevDayAppointments = () => {
        if (dayIndex !== 0) {
            const counter = dayIndex - 1;
            setDayIndex(counter);
        }
    }

    const onSelectTime = (value) => {
        setSelectedTime(value)
    }

    const onSelectOffer = (value) => {
        localStorage.removeItem('offer')
        if (value) {
            localStorage.setItem('offer', JSON.stringify(value))
        }
    }

    const navigateToLab = () => {
        history.push(`/lab/${offer?.owner?.uuid}/${offer?.id}`)
    }

    const onBookingDoctor = (event) => {
        setButtonDisabled(false)
        if (!event) {
            setButtonDisabled(true)
            return;
        }
        if (user) {
            history.push("/booking-doctor/" + event + '/' + offer?.doctor?.uuid + `?video=${video}&offer_id=${offer?.id}`);
        } else {
            localStorage.setItem('previous-link', history?.location?.pathname)
            setShowModal(true)
        }
    }

    const closeModalHandler = () => {
        setShowModal(false);
    }

    return(
        <div className='offer-details-container'>
            <Skeleton active={true} loading={loading} avatar>
                <div className='left'>
                    <div className='images'>
                        <div className='main-image'>
                            <img src={offer?.images[0].image} alt="" />
                            <img src={process.env.PUBLIC_URL + '/assets/images/star.svg'} alt="" />
                            <span>{Math.round(offer?.discount_percentage)}%</span>
                        </div>
                    </div>

                    {offer?.details && <div className='info'>
                        <div className='details-title'>
                            <img src={process.env.PUBLIC_URL + '/assets/images/info.svg'} alt="" />
                            <h3>معلومات العرض</h3>
                        </div>
                        <div className='details-description'>
                            <p>{offer?.details}</p>
                            <p className="end-date"> تاريخ انتهاء العرض: <span>{moment(offer?.end_at).format('DD MMMM yyyy')}</span></p>
                        </div>
                    </div>}

                    <div className='info'>
                        <div className='details-title'>
                            <img src={process.env.PUBLIC_URL + '/assets/images/doctor-icon.svg'} alt="" />
                            <h3>الطبيب المقدم للخدمة</h3>
                        </div>
                        <div className='doctor-description'>
                            <img src={offer?.doctor?.image} alt="" />
                            <div>
                                <p>دكتور/ {offer?.doctor?.name}</p>
                                <p>{offer?.doctor?.title_details}</p>
                            </div>
                        </div>
                    </div>

                    {offer?.owner?.name && <div className='info'>
                        <div className='details-title'>
                            <img src={process.env.PUBLIC_URL + '/assets/images/doctor-icon.svg'} alt="" />
                            <h3>المركز التابع له</h3>
                        </div>
                        <div className='details-description'>
                            <p>مركز {offer?.owner?.name}</p>
                        </div>
                    </div>}
                </div>

                <div className='right'>
                    <div className='right-title'>
                        <p>معلومات العرض</p>
                    </div>
                    <img className='share' src={process.env.PUBLIC_URL + '/assets/images/share.svg'} />
                    <h2>{offer?.name}</h2>
                    <p>جلسة واحدة</p>
                    {/* <h2>{offer?.offer_specialty?.name}</h2> */}

                    <div className='offer-price'>
                        <div>
                            <span>{offer?.price} ريال</span>
                            <span>وفر {offer?.price - offer?.price_after_discount} ريال</span>
                        </div>
                        <p>{offer?.price_after_discount} ريال</p>
                    </div>

                    <div className='offer-price-address'>
                        <div className='element'>
                            <img src={process.env.PUBLIC_URL + '/assets/images/price.svg'} alt="" />
                            <span>سعر العرض:</span>
                            <p>{offer?.price_after_discount} ريال</p>
                        </div>
                        <div className='element'>
                            <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                            <p>{offer?.owner?.address}</p>
                        </div>
                    </div>

                    {/* {offer?.type === 'medical_centers' && <div className='doctors'>
                        <h3>الأطباء المتاحين للعرض</h3>
                        <div>
                            <img src={offer?.doctor?.image} alt={offer?.doctor?.name} />
                            <span>{offer?.doctor?.name}</span>
                        </div>
                    </div>}
                    {offer?.type === 'labs' && <div className='doctors labs' onClick={navigateToLab}>
                        <h3>حجز التحاليل الموجودة داخل العرض</h3>
                    </div>} */}

                    {offer?.type !== 'labs' ? <div className="booking-info online">
                        <span style={{"fontWeight": "bold"}}>اختار موعد الحجز</span>
                        <div className="appointments">
                            <div className="timing">
                                {appointments?.length > 0  ?
                                    <AppointmentSlider isDoctor={true} onSelectOffer={onSelectOffer} onBookingDoctor={onBookingDoctor} appointments={appointmentsBody} />
                                    :
                                    <p>لا يوجد حجوزات</p>
                                }
                            </div>
                            {/* <div className="button">
                                <button onClick={onBookingDoctor}>
                                    احجز
                                </button>
                            </div> */}
                        </div>
                    </div> : ''}
                </div>
                {showModal && <Modal title="" visible={showModal} footer={null} maskClosable={false} onCancel={closeModalHandler} width={600}>
                    <Login url={`/booking-doctor/${selectedTime}/${id}`} />
                </Modal>}
            </Skeleton>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(OfferDetails);