import './discount.scss';

import { useRef, useState } from 'react';
import { submitOfferForm } from '../../data/api/global-api';
import { message, Input } from 'antd';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

const Discount = () => {
    const name = useRef();
    const email = useRef();
    const [phone, setPhone] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const onSubmit = () => {
        if (!name.current.value || !email.current.value || !phone) {
            return;
        } else {
            const body = {
                name: name.current.value,
                email: email.current.value,
                mobile: '+966' + phone
            }

            submitOfferForm(body).then(
                res => {
                    setShowModal(true);
                    name.current.value = '';
                    email.current.value = '';
                    setPhone(null);
                }
            ).catch(
                error =>{
                    if (error?.response?.data?.errors?.mobile) {
                        message.error(error?.response?.data?.errors?.mobile[0]);
                    } else if (error?.response?.data?.errors?.email) {
                        message.error(error?.response?.data?.errors?.email[0])
                    } else {
                        message.error('حدث خطأ ما');
                    }
                }
            )
        }
    }

    const closeModalHandler = () => {
        setShowModal(false);
    }

    return (
        <div className="discount-container">
            <div className='discount-background-color'></div>
            <div className='discount-background-image'></div>
            <div className='discount-content'>
                <div className='left'>
                    <h1>سجل الان</h1>
                    <div>
                        <p>واحصل على خصم <br /> لكشفيتك الاولى</p>
                        <p>25%</p>
                    </div>
                    <div className='discount-form'>
                        <input type="text" ref={name} placeholder='الأسم' />
                        <input type="email" ref={email} placeholder='الايميل' />
                        <Input suffix="+966" type="text" onChange={(e) => setPhone(e.target.value)} value={phone} placeholder='رقم التواصل' />
                        <button onClick={onSubmit}>تسجيل</button>
                    </div>
                </div>
                <div className='right'>
                    <img src={process.env.PUBLIC_URL + '/assets/images/discount-percentage.png'} alt="" />
                    <img src={process.env.PUBLIC_URL + '/assets/images/discount-doctors.png'} alt="" />
                </div>
            </div>

            <Modal title="" visible={showModal} footer={null} closable={false} wrapClassName="success-modal" maskClosable={false} onCancel={closeModalHandler} width={600}>
                <div className="booking-success">
                    <div className="image">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/check.svg'} alt="" />
                        </div>
                    </div>
                    <div className="success-data">
                        <h3>شكرا لك</h3>
                        <p>يمكنك تصفح أطبائنا الآن</p>
                        <button type="" style={{marginBottom: '40px'}}>
                            <Link to="/search-doctor">تصفح الأطباء</Link>
                        </button>
                        {/* <Link to="/home">الصفحة الرئيسية</Link> */}
                    </div>                    
                </div>
            </Modal>
        </div>
    )
}

export default Discount;