import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'antd';
import './confirmation-modal.scss';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { verifyAccount } from '../../../data/api/auth-api';

const ConfirmationModal = ({showModal, closeModalHandler, mobile, url}) => {
    const [codeOne, setCodeOne] = useState(null);
    const [codeTwo, setCodeTwo] = useState(null);
    const [codeThree, setCodeThree] = useState(null);
    const [codeFour, setCodeFour] = useState(null);
    const codeOneRef = useRef();
    const codeTwoRef = useRef();
    const codeThreeRef = useRef();
    const codeFourRef = useRef();

    const history = useHistory();

    useEffect(() => {
        codeFourRef?.current?.focus()
        // document.querySelectorAll('input').forEach((input) => {
        //     input.oninput = function() {
        //       let { nextElementSibling } = this;
        //       while (nextElementSibling && nextElementSibling.tagName !== 'INPUT') {
        //         nextElementSibling = nextElementSibling.prevElementSibling;
        //       }
        //       if (nextElementSibling) {
        //         nextElementSibling.focus();
        //       }
        //     }
        // });
    }, [])

    
    const verifyUserHandler = () => {
        const body = {
            mobile: mobile.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', ''),
            code: [codeFour, codeThree, codeTwo, codeOne].join('')
        }
        verifyAccount(body).then(
            res => {
                closeModalHandler();
                message.config({
                    rtl: true
                });
                message.success('تم تفعيل حسابك بنجاح');
                history.push(url);
            }
        ).catch(
            error => {
                message.config({
                    rtl: true
                });
                message.error('الكود غير صحيح');
            }
        )
    }

    return (
        <Modal title="" visible={showModal} footer={null} maskClosable={false} onCancel={closeModalHandler} width={600}>
            <div className="modal-container content">
                <h3>كود التأكيد</h3>
                <p>تم إرسال رسالة نصية ل <span id="mobile">{mobile}</span> يرجى إدخال الكود لإكمال الخطوات</p>
                <div className="inputs">
                    <input ref={codeOneRef} type="text" maxLength="1" onKeyUp={event => {
                        setCodeOne(event.target.value)
                    }} />
                    <input ref={codeTwoRef} type="text" maxLength="1" onKeyUp={event => {
                        setCodeTwo(event.target.value)
                        codeOneRef?.current?.focus()
                    }} />
                    <input ref={codeThreeRef} type="text" maxLength="1" onKeyUp={event => {
                        setCodeThree(event.target.value)
                        codeTwoRef?.current?.focus()
                    }} />
                    <input ref={codeFourRef} type="text" maxLength="1" onKeyUp={event => {
                        setCodeFour(event.target.value)
                        codeThreeRef?.current?.focus()
                    }} />
                </div>
                <div className="button-container">
                    <button type="" onClick={verifyUserHandler}>تأكيد</button>
                    {/* <p>لم يصلك رسالة إعادة إرسال</p> */}
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationModal;