import cookies from 'js-cookie';

const reducer = (state = initialState(), action) => {
    if (action.type === 'LOGIN') {
        cookies.set('user', JSON.stringify(action.payload));
        state.user = action.payload.data;
        state.token = action.payload.token;
        return {
            user: action.payload.data,
            token: action.payload.token
        }
    } else if (action.type === 'LOGOUT') {
        localStorage.clear();
        state.user = null;
        state.token = null;
        return {
            user: null,
            token: null
        }
    }
    return state
}

const initialState = () => {
    return {
        user: localStorage.getItem('user_info')|| null,
        token: localStorage.getItem('login_token') || null
    }
}

export default reducer;