import { useState } from 'react';
import { DatePicker } from 'antd';
import { Form, Input, Button, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { changePasswordApi, getProfileApi, register, updateProfileApi } from '../../../../data/api/auth-api';
import { useEffect } from 'react';
import { Skeleton } from "antd";
import './profile-data.scss';
import moment from 'moment';
import { Modal } from 'antd';

const ProfileData = () => {
    const [showModal, setShowModal] = useState(false);
    const [mobile, setMobile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [user, setUser] = useState();

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        getProfileData();
    }, [])

    const getProfileData = () => {
        setSkeletonLoading(true);
        getProfileApi().then(
            res => {
                setUser(res.data.data);
                setSkeletonLoading(false);
            }
        )
    }

    const onFinish = (values) => {
        setLoading(true);
        const body = {
            full_name: values.username,
            mobile: values.phone,
            email: values.email,
            sex: values.gender,
            birth_date: moment(values.birth_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        }
        setMobile(values.phone);
        updateProfileApi(body).then(
            res => {
                setLoading(false);
                message.success('تم تحديث البيانات بنجاح');
            }
        ).catch(error => {
            setLoading(false);
            message.config({
                rtl: true
            })
            message.error(Object.values(error.response.data?.errors)[0][0] || 'حدث خطأ ما');
        })
    };

    const onChangePassword = (values) => {
        setLoading(true);
        const body = {
            password: values.newPassword,
            password_confirmation: values.confirmPassword,
            old_password: values.password
        }
        setMobile(values.phone);
        changePasswordApi(body).then(
            res => {
                setLoading(false);
                setShowModal(false);
                message.success('تم كلمة المرور بنجاح');
            }
        ).catch(error => {
            setLoading(false);
            message.config({
                rtl: true
            })
            message.error(Object.values(error.response.data?.errors)[0][0] || 'حدث خطأ ما');
        })
    };


    return (
        <div className="profile-data">
            <div className="content">
                <Skeleton className="" active={true} loading={skeletonLoading} avatar>
                    <Form 
                        name="basic"
                        className="form"
                        // layout="vertical"
                        // wrapperCol={{ span: 16 }}
                        initialValues = {{
                            username: user?.full_name,
                            phone: user?.mobile,
                            email: user?.email,
                            birth_date: moment(user?.birth_date, 'YYYY-MM-DD'),
                            gender: user?.sex
                        }}
                        onFinish={onFinish}
                    >
                        <div className="fields">
                            <div className="field name">
                                <Form.Item label="الإسم الكامل" name="username" rules={[{ required: true, message: 'من فضلك أدخل الإسم الكامل' }]}>
                                    <Input placeholder="أكتب الإسم الكامل" />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="fields">
                            <div className="field">
                                <Form.Item label="رقم الجوال" name="phone" rules={[{ required: true, message: 'من فضلك أدخل رقم جوال صحيح' }]}>
                                    <Input placeholder="أكتب رقم الجوال" value={mobile} />
                                </Form.Item>
                            </div>
                            <div className="field">
                                <Form.Item label="البريد الإلكترونى" name="email" rules={[{ required: true, type: 'email', message: 'من فضلك أدخل البريد الإلكترونى بشكل صحيح'  }]}>
                                    <Input placeholder="أكتب البريد الإلكترونى" />
                                </Form.Item>
                            </div>
                        </div>

                        <div className="fields">
                            <div className="field">
                                <Form.Item name="birth_date" label="تاريخ الميلاد" rules={[{ required: true, message: 'من فضلك أدخل تاريخ الميلاد' }]}>
                                    <DatePicker placeholder="أختر تاريخ الميلاد" />
                                </Form.Item>
                            </div>
                            <div className="field">
                                <Form.Item name="gender" label="النوع" rules={[{ required: true, message: 'من فضلك أختر النوع' }]}>
                                    <Radio.Group>
                                        <Radio value='male'>ذكر</Radio>
                                        <Radio value='female'>أنثى</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>

                        <span className="change-text" onClick={() => setShowModal(true)}>تغيير كلمة المرور</span>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                حفظ التغييرات
                            </Button>
                        </Form.Item>
                    </Form>
                </Skeleton>
                <Modal width={430} title="" visible={showModal} footer={null} maskClosable={false} onCancel={() => setShowModal(false)} width={600}>
                    <div className="change-password">
                        <h3>تغيير كلمة المرور</h3>
                        <Form 
                            name="change-password"
                            className="form"
                            layout="vertical"
                            onFinish={onChangePassword}
                        >
                            <div className="fields">
                                <div className="field">
                                    <Form.Item label="كلمة المرور الحالية" name="password" rules={[{ required: true, message: 'من فضلك أدخل كلمة المرور' }]}>
                                        <Input.Password placeholder="كلمة المرور الحالية" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="fields">
                                <div className="field">
                                    <Form.Item label=" كلمة المرور" name="newPassword" 
                                        rules={[
                                            { required: true, message: 'من فضلك أدخل كلمة المرور' },
                                        ]}
                                    >
                                        <Input.Password placeholder=" كلمة المرور" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="fields">
                                <div className="field">
                                    <Form.Item label="تأكيد كلمة المرور" name="confirmPassword" 
                                        rules={[
                                            { required: true, message: 'من فضلك أدخل تأكيد كلمة المرور' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('كلمة المرور غير متطابقة'));
                                                }
                                            }),
                                        ]}
                                    >
                                        <Input.Password placeholder="تأكيد كلمة المرور" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="button-container">
                                <button type="">تغيير</button>
                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
            <Backdrop className='backdrop' open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            
        </div>
        
    )
}

export default ProfileData;