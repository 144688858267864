import './public-info.scss';
import { useEffect, useState } from 'react';
import { getProfileApi, updateProfileInfoApi } from '../../../../data/api/auth-api';
import { Skeleton } from "antd";
import { Form, Input, Button, Select } from 'antd';
import TextArea from 'rc-textarea';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { message } from 'antd';

const { Option } = Select;


const PublicInfo = () => {
    const [editProfile, setEditProfile] = useState(true);
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        getProfileData();
    }, [])

    const getProfileData = () => {
        setSkeletonLoading(true);
        getProfileApi().then(
            res => {
                if (res.data.data?.profile?.height) {
                    setEditProfile(false);
                } else {
                    setEditProfile(true);
                }
                setSkeletonLoading(false);
                setUser(res.data.data);
            }
        )
    }

    const onFinish = (values) => {
        setLoading(true);
        const body = {
            blood_type: values.blood,
            height: values.height,
            weight: values.weight,
            sensitivity: values.sense,
            medical_condition: values.medicine,
        }
        updateProfileInfoApi(body).then(
            res => {
                setEditProfile(false);
                setLoading(false);
                setUser(res.data.data);
                message.success('تم تحديث البيانات بنجاح');
            }
        ).catch(error => {
            setLoading(false);
            message.config({
                rtl: true
            })
            message.error(Object.values(error.response.data?.errors)[0][0] || 'حدث خطأ ما');
        })
    };

    return(
        <div className="public-info">
            <Skeleton className="" active={true} loading={skeletonLoading} avatar>
            {!editProfile && 
                <div className="info-content">
                    <button type="button" className="edit" onClick={() => {setEditProfile(true)}}>تعديل المعلومات العامة</button>
                    <div className="field">
                        <div className="data">
                            <h3>الطول: </h3>
                            <p>{user?.profile?.height || user?.height} cm</p>
                        </div>
                        <div className="data">
                            <h3>فصيلة الدم: </h3>
                            <p>{user?.profile?.blood_type || user?.blood_type}</p>
                        </div>
                        <div className="data">
                            <h3>الوزن: </h3>
                            <p>{user?.profile?.weight || user?.weight} kg</p>
                        </div>
                    </div>
                    <div className="sense">
                        <h3>الحساسية</h3>
                        <p>{user?.profile?.sensitivity || user?.sensitivity}</p>
                    </div>
                    <div className="medicine">
                        <h3>الحالات الطبية</h3>
                        <p>{user?.profile?.medical_condition || user?.medical_condition}</p>
                    </div>
                </div>
            }
            {editProfile && <div className="info-form">
                <Form 
                    name="basic"
                    className="form"
                    layout="vertical"
                    initialValues = {{
                        blood: user?.profile?.blood_type || user?.blood_type,
                        height: user?.profile?.height || user?.height,
                        weight: user?.profile?.weight || user?.weight,
                        sense: user?.profile?.sensitivity || user?.sensitivity,
                        medicine: user?.profile?.medical_condition || user?.medical_condition
                    }}
                    onFinish={onFinish}
                >
                    <div className="fields">
                        <div className="field name">
                            <Form.Item label="الطول" name="height" rules={[{ required: true, message: 'من فضلك أدخل الطول' }]}>
                                <Input placeholder="أدخل الطول" />
                            </Form.Item>
                            <span className="suffix">cm</span>
                        </div>
                        <div className="field name">
                            <Form.Item label="فصيلة الدم" name="blood" rules={[{ required: true, message: 'من فضلك اختر فصيلة الدم' }]}>
                            <Select
                                placeholder="اختر فصيلة الدم"
                                allowClear
                            >
                                <Option value="a+">a+</Option>
                                <Option value="a-">a-</Option>
                                <Option value="b+">b+</Option>
                                <Option value="b-">b-</Option>
                                <Option value="ab+">ab+</Option>
                                <Option value="ab-">ab-</Option>
                                <Option value="o+">o+</Option>
                                <Option value="o-">o-</Option>
                            </Select>
                            </Form.Item>
                        </div>
                        <div className="field name">
                            <Form.Item label="الوزن" name="weight" rules={[{ required: true, message: 'من فضلك أدخل الوزن' }]}>
                                <Input placeholder="أدخل الوزن" />
                            </Form.Item>
                            <span className="suffix">Kg</span>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="field sense">
                            <Form.Item label="الحساسية" name="sense">
                                <TextArea placeholder="أدخل الحساسية" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="field medicine">
                            <Form.Item label="الحالات الطبية" name="medicine">
                                <TextArea placeholder="أدخل الحالات الطبية" />
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            حفظ التغييرات
                        </Button>
                    </Form.Item>
                </Form>
            </div>}
            </Skeleton>
            <Backdrop className='backdrop' open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default PublicInfo;