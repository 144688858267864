import './medical-result.scss';
import { Pagination, Select } from 'antd';
import { Skeleton, Empty } from "antd";
import { Rate } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useState } from 'react';

const { Option } = Select;

const MedicalResult = ({doctors, loading, meta, getDoctorsMethod}) => {
    const history = useHistory();
    const [page, setPage] = useState(1)

    const onChangePage = (event) => {
        setPage(event)
        getDoctorsMethod(event);
    }

    const navigateToDoctor = (event, id) => {
        event.stopPropagation();
        history.push(`/medical-center/${id}`)
    }

    return(
        <div className="medical-result">
            <div className="header-title">
                <p className="">كل المراكز ({meta?.total})</p>
                {/* <div className="sort col-6">
                    <label for="">الترتيب</label>
                    <Select defaultValue="lucy" style={{ width: '100%' }}>
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="disabled" disabled>
                            Disabled
                        </Option>
                        <Option value="Yiminghe">yiminghe</Option>
                    </Select>
                </div> */}
            </div>

            <div className="">
                <Skeleton className="" active={true} loading={loading} avatar>
                    {doctors.length == 0 ? (
                        <div className="">
                            <Empty
                                description={
                                <span className="font-Lsmall weight-600 text-theme-color">
                                    لا يوجد مراكز
                                </span>
                                }
                            />
                        </div>
                    ) : (
                        doctors.map((doctor) => (
                            <div key={doctor.id} className="element" onClick={(event) => navigateToDoctor(event, doctor.uuid)}>
                                <div className="doctor-image">
                                    <div className="images">
                                        <img className="doctor-image-element" src={doctor.image} alt={doctor.name} />
                                    </div>
                                </div>
                                <div className="doctor-data">
                                    <div className="doctor-name">
                                        <p>{doctor.name}</p>
                                    </div>
                                    { doctor?.description ?<div className="doctor-description">
                                        <p>{doctor?.description}</p>
                                    </div> : ''}
                                    <div className="price" style={{"display": "flex", "flexWrap": "nowrap"}}>
                                        <a target="_blank" href={`https://maps.google.com/?q=${doctor.latitude},${doctor.longitude}`}>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                            <p>{doctor.address}</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="doctor-rate">
                                    {/* <div className="rate">
                                        <Rate allowHalf disabled defaultValue={5} />
                                    </div> */}
                                    
                                    {/* <div className="booking">
                                        <a href="">عرض</a>
                                    </div> */}
                                </div>
                            </div>
                        ))
                    )}
                    {meta?.current_page * 15 < meta?.total ?<Pagination pageSize={15} defaultCurrent={page} total={meta?.total} onChange={onChangePage} />: '' }
                </Skeleton>
                
            </div>

        </div>
    )
}

export default MedicalResult;