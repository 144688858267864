import './lab-orders.scss';
import { Tabs } from 'antd';
import Bookings from '../bookings/bookings';
import OrdersList from '../orders-list/orders-list';


const { TabPane } = Tabs;

const LabOrders = () => {
    const callback = (key) => {
        console.log(key);
    }

    return (
        <div className="labOrders-container">
            <div className="content">
                <Tabs onChange={callback} type="card">
                    <TabPane tab="الحالية" key="1">
                        <OrdersList type="1" />
                    </TabPane>
                    <TabPane tab="السابقة" key="2">
                        <OrdersList type="2" />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default LabOrders;