import Appointment from '../component/appointment/appointment';
import './my-account.scss';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Select } from 'antd';
import LabOrders from '../component/lab-orders/lab-orders';
import Profile from '../component/profile/profile';
import MedicalFile from '../component/medical-file/medical-file';

const { Option } = Select;

const MyAccount = (props) => {
    const history = useHistory();
    const user = props?.user;
    const [optionValue, setOptionValue] = useState(1);

    const logout = () => {
        props.onLogout();
        history.push('/home');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const onMenuChange = (value) =>  {
        setOptionValue(value)
        if (value === '6') {
            logout();
        }
    }

    return (
        <div className="my-account">
            <div className="account-name">
                <p>مرحبا <span>{user?.full_name}</span></p>
                {/* <div className="points">
                    <div className="win-points">
                        <p>نقاطك التى فزت بها</p>
                        <span>112 نقطة</span>
                    </div>
                    <div className="price">
                        <p>الرصيد</p>
                        <span>10 ر.س</span>
                    </div>
                </div> */}
            </div>

            <div className="menu">
                <div className="mobile-menu">
                    <Select defaultValue="1" style={{ width: 200 }} onChange={(event) => onMenuChange(event)}>
                        <Option value="1">مواعيدى</Option>
                        <Option value="2">الملف الطبى</Option>
                        <Option value="3">سجل حجوزات المختبرات</Option>
                        {/* <Option value="4">المفضلة</Option> */}
                        <Option value="5">بياناتى</Option>
                        <Option value="6">تسجيل الخروج</Option>
                    </Select>
                </div>
                <div className="right">
                    <div className="items">
                        <div className={optionValue === 1 ? 'element active' : 'element'} onClick={() => onMenuChange(1)}>
                            <div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/calendar.svg'} alt="" />
                                </div>
                                <p>مواعيدى</p>
                            </div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/menu-arrow.svg'} alt="" />
                        </div>
                        <div className={optionValue === 2 ? 'element active' : 'element'} onClick={() => onMenuChange(2)}>
                            <div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/health-report.svg'} alt="" />
                                </div>
                                <p>الملف الطبى</p>
                            </div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/menu-arrow.svg'} alt="" />
                        </div>
                        <div className={optionValue === 3 ? 'element active' : 'element'} onClick={() => onMenuChange(3)}>
                            <div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/booking-report.svg'} alt="" />
                                </div>
                                <p>سجل حجوزات المختبرات</p>
                            </div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/menu-arrow.svg'} alt="" />
                        </div>
                        {/* <div className={optionValue === 4 ? 'element active' : 'element'} onClick={() => onMenuChange(4)}>
                            <div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/favorite.svg'} alt="" />
                                </div>
                                <p>المفضلة</p>
                            </div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/menu-arrow.svg'} alt="" />
                        </div> */}
                        <div className={optionValue === 5 ? 'element active' : 'element'} onClick={() => onMenuChange(5)}>
                            <div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/user.svg'} alt="" />
                                </div>
                                <p>بياناتى</p>
                            </div>
                            <img src={process.env.PUBLIC_URL + '/assets/images/menu-arrow.svg'} alt="" />
                        </div>
                    </div>
                    <div className="logout">
                        <p onClick={logout}>تسجيل الخروج</p>
                    </div>
                </div>
                <div className="item-data">
                    {optionValue == 1 && <Appointment />}
                    {optionValue == 2 && <MedicalFile />}
                    {optionValue == 3 && <LabOrders />}
                    {optionValue == 5 && <Profile />}
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch({type: 'LOGOUT'})
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);