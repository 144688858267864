import { useState } from 'react';
import { DatePicker } from 'antd';
import { Form, Input, Button, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { register } from '../../../../data/api/auth-api';
import { useEffect } from 'react';
import NumberFormat from 'react-number-format';

import './register.scss';
import ConfirmationModal from '../../../../shared/component/confirmation-modal/confirmation-modal';


const Register = () => {
    const { t } = useTranslation();
    const [displayPassword, setDisplayPassword] = useState(false);
    const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [mobile, setMobile] = useState(null);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const togglePassword = () => {
        setDisplayPassword(!displayPassword);
    }

    const toggleConfirmPassword = () => {
        setDisplayConfirmPassword(!displayConfirmPassword);
    }

    const closeModalHandler = () => {
        setShowModal(false);
        history.push('/login')
    }

    const onFinish = (values) => {
        setLoading(true);
        const body = {
            full_name: values.username,
            mobile: values.phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', ''),
            email: values.email,
            sex: values.gender,
            birth_date: '1998-8-11',
            password: values.password,
            password_confirmation: values.confirmPassword
        }
        setMobile(values.phone);
        register(body).then(
            res => {
                setLoading(false);
                // setShowModal(true);
                history.push('/login')
            }
        ).catch(error => {
            setLoading(false);
            message.config({
                rtl: true
            })
            message.error(Object.values(error.response.data?.errors)[0][0] || 'حدث خطأ ما');
        })
    };

    return (
        <div className="register">
            <div className="content">
                <h3>إنشاء حساب</h3>
                <Form 
                    name="basic"
                    className="form"
                    // layout="vertical"
                    // wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                >
                    <div className="fields">
                        <div className="field name">
                            <Form.Item label="الإسم الكامل" name="username" rules={[{ required: true, message: 'من فضلك أدخل الإسم الكامل' }]}>
                                <Input placeholder="أكتب الإسم الكامل" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="field">
                            <Form.Item label="رقم الجوال" name="phone" rules={[{ required: true, message: 'من فضلك أدخل رقم جوال صحيح' }]}>
                                {/* <Input placeholder="أكتب رقم الجوال" value={mobile} /> */}
                                <NumberFormat
                                    type="tel"
                                    allowEmptyFormatting
                                    autocomplete="address-level4"
                                    format="(+966)  #########"
                                    autoComplete="off"
                                />
                            </Form.Item>
                        </div>
                        <div className="field">
                            <Form.Item label="البريد الإلكترونى" name="email">
                                <Input placeholder="أكتب البريد الإلكترونى" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="field password">
                            <Form.Item label="كلمة المرور" name="password" rules={[{ required: true, message: 'من فضلك أدخل كلمة المرور' }]}>
                                {/* <span className="display-password" onClick={togglePassword}>
                                    {displayPassword ? 'إخفاء' : 'عرض'}
                                </span> */}
                                <Input.Password placeholder="كلمة المرور" />
                            </Form.Item>
                        </div>
                        <div className="field password">
                            <Form.Item label="تأكيد كلمة المرور" name="confirmPassword" 
                                rules={[
                                    { required: true, message: 'من فضلك أدخل تأكيد كلمة المرور' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('كلمة المرور غير متطابقة'));
                                        }
                                    }),
                                ]}
                            >
                                {/* <span className="display-password" onClick={toggleConfirmPassword}>
                                    {displayConfirmPassword ? 'إخفاء' : 'عرض'}
                                </span> */}
                                <Input.Password placeholder="تأكيد كلمة المرور" />
                            </Form.Item>
                            {/* {passwordError ? <label className="error">كلمة السر غير متطابقة</label> : '' } */}
                        </div>
                    </div>

                    <div className="fields">
                        <div className="field">
                            <Form.Item name="date-picker" label="تاريخ الميلاد" >
                                <DatePicker placeholder="أختر تاريخ الميلاد" />
                            </Form.Item>
                        </div>
                        <div className="field">
                            <Form.Item name="gender" label="النوع">
                                <Radio.Group>
                                    <Radio value='male'>ذكر</Radio>
                                    <Radio value='female'>أنثى</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            تسجيل
                        </Button>
                    </Form.Item>
                    <Link to="/login" id="register-link"><span>لديك حساب؟ </span> سجل الدخول الأن</Link>
                </Form>
            </div>
            <p>من خلال تسجيل الدخول . فأنك توافق على شروط الأستخدام و سياسة الخصوصية</p>
            {showModal && <ConfirmationModal showModal={showModal} mobile={mobile} url="/login" closeModalHandler={closeModalHandler} />}
            <Backdrop className='backdrop' open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
        
    )
}

export default Register;