import SearchComponent from '../../search/component/search-component/search-component';
import './home.scss';
import { useEffect } from 'react'; 
import Pharmacy from '../component/pharmacy/pharmacy';
import OurService from '../component/our-service/our-service';
import OurApp from '../component/our-app/our-app';
import OurPartner from '../component/our-partner/our-partner';
import ContactUs from '../component/contact-us/contact-us';
import Offers from '../component/offers/offers';
import Benefits from '../component/benefits/benefits';
import Parteners from '../component/parteners.js/parteners';
import Blog from '../component/blog/blog';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className="home">
            <div className="background-home">
                <div className="content">
                    <h3>خدمات طبية متكاملة.. وحجوزات أسهل</h3>
                    <p>دكتور سبيس منصة إلكترونية تتيح لك حجز المواعيد مع المراكز الطبية وإدارتها بكل سهولة .. مع نخبة<br></br> من الأطباء الكفاءات من مختلف التخصصات <br></br>
اطلب طبيبك، تحاليلك وجميع خدمات الرعاية وانت بالمنزل</p>
                </div>
            </div>

            <SearchComponent />
            {/* <Pharmacy /> */}
            <OurService />
            <Offers />
            <Benefits />
            <Parteners />
            {/* <OurApp /> */}
            <OurPartner />
            <Blog />
            <ContactUs />
            <img src={process.env.PUBLIC_URL + '/assets/images/home-logo.svg'} alt='' />
        </div>
    )
    
}

export default Home;
