import React from 'react';

import { Route, Redirect } from 'react-router-dom';

const RouteWrapper = ({component: Component, isPrivate, ...rest}) => {
    const signed = false;

    if (isPrivate && !signed) {
        return <Redirect to="/" />
    }

    if (!isPrivate && signed) {
        return <Redirect to="/home" />
    }

    return <Route {...rest} component={Component} />
    
}

// RouteWrapper.defaultProps = {
//     isPrivate: false
// }

export default RouteWrapper;