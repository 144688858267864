import './appointment.scss';
import { Tabs } from 'antd';
import Bookings from '../bookings/bookings';


const { TabPane } = Tabs;

const Appointment = () => {
    const callback = (key) => {
        console.log(key);
    }

    return (
        <div className="appointment-container">
            <div className="content">
                <Tabs onChange={callback} type="card">
                    <TabPane tab="الحجوزات" key="1">
                        <Bookings />
                    </TabPane>
                    {/* <TabPane tab="العروض" key="2">
                        <Bookings />
                    </TabPane> */}
                </Tabs>
            </div>
        </div>
    )
}

export default Appointment;