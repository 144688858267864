import './profile.scss';
import { Tabs } from 'antd';
import ProfileData from '../profile-data/profile-data';


const { TabPane } = Tabs;

const Profile = () => {
    const callback = (key) => {
        console.log(key);
    }

    return (
        <div className="labOrders-container">
            <div className="content">
                <Tabs onChange={callback} type="card">
                    <TabPane tab="بياناتى" key="1">
                        <ProfileData />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default Profile;