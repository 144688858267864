import './offers.scss';
import { useState, useEffect } from 'react';
import { getAllOffersApi } from '../../../../data/api/offers-api';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Offers = () => {
    const history = useHistory();
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        getOffers();
    }, [])

    const getOffers = () => {
        getAllOffersApi('', 1).then(
            res => {
                setOffers(res.data?.data?.splice(0,3));
            }
        )
    }

    return(
        <>
        {offers.length ? <div className="offers">
            <div className="offers-header">
                <h4>عروضنا</h4>
                {/* <Link to="/offers"> تصفح جميع العروض <img src={process.env.PUBLIC_URL + '/assets/images/arrow.svg'} alt="" /></Link> */}
            </div>
            <div className="offers-data">
                {offers?.map((offer, index) => (
                    <div className="element" key={index} onClick={() => history.push(`/offer/${offer?.uuid}`)}>
                        <div className="top-header">
                            <p>{Math.round(offer?.discount_percentage)}%</p>
                            <img src={process.env.PUBLIC_URL + '/assets/images/star.svg'} alt="" />
                            <div className='overlay-background'></div>
                            <div className='overlay-image'>
                                <img src={offer?.images[0]?.image} alt='' />
                            </div>
                            <div className="doctor-name">
                                {offer?.owner?.image && <img style={{borderRadius: '50%'}} src={offer?.owner?.image} alt="" />}
                                <div>
                                    <p>{offer?.owner?.name} - {offer?.owner?.address}</p>
                                    <p>{offer?.doctor?.address}</p>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="specialty">
                                {offer?.name}
                            </div>
                            <div className="operation">
                                جلسة واحدة
                            </div>
                            <div className="price">
                                <p>
                                    <span>{offer?.price} ريال</span>
                                    <span>وفر {offer?.price - offer?.price_after_discount} ريال</span>
                                </p>
                                <div className='bottom-part'>
                                    <span>{offer?.price_after_discount} ريال</span>
                                    <button type="">حجز</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <div className='all-offers'>
                <Link to="/offers">كل العروض</Link>
                <img src={process.env.PUBLIC_URL + '/assets/images/all-offers.svg'} alt='' />
            </div>
        </div> : ''}
        </>
    )
}

export default Offers;