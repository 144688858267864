import { Link } from 'react-router-dom';
import './login.scss';
import { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmationModal from '../../../../shared/component/confirmation-modal/confirmation-modal';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { forgetPasswordMobileApi, login, resetPasswordMobileApi } from '../../../../data/api/auth-api';
import { connect } from 'react-redux';
import { setStorage } from "../../../../shared/helper/functions";
import { Modal } from 'antd';
import { Form, Input} from 'antd';
import NumberFormat from 'react-number-format';

const LoginWrapper = (props) => {
    const [displayPassword, setDisplayPassword] = useState(false);
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [mobileError, setMobileError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [resetMobile, setResetMobile] = useState('');

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const togglePassword = () => {
        setDisplayPassword(!displayPassword);
    }

    const closeModalHandler = () => {
        setShowModal(false);
    }

    const onChangeMobileHandler = (event) => {
        event.target.value.length ? setMobileError(false) : setMobileError(true);
        setMobile(event.target.value)
    }

    const onChangePasswordHandler = (event) => {
        event.target.value.length ? setPasswordError(false) : setPasswordError(true);
        setPassword(event.target.value)
    }

    const onLoginHandler = (event) => {
        event.preventDefault();
        !mobile?.length ? setMobileError(true) : setMobileError(false);
        

        if (!password.length) {
            setPasswordError(true);
            return;
        } else {
            setPasswordError(false);
        }

        const body = {
            password: password
        }

        // if (mobile.includes('+966')) {
        //     body['mobile'] = mobile;
        // } else {
        //     body['mobile'] = `+966${mobile}`
        // }

        body['mobile'] = mobile.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '')

        setLoading(true);

        login(body).then(
            res => {
                setLoading(false);
                // if (res?.data?.data?.status != 1) {
                //     setShowModal(true);
                // } else {
                //     setStorage("login_token", res.data.token);
                //     setStorage("user_info", JSON.stringify(res.data.data));
                //     props.onLogin('LOGIN', res.data);
                //     if (localStorage.getItem('previous-link')) {
                //         history.push(localStorage.getItem('previous-link'))
                //         window.location.reload()
                //     } else {
                //         props.url ? history.push(props.url) : history.push("/home");
                //     }
                // }
                setStorage("login_token", res.data.token);
                setStorage("user_info", JSON.stringify(res.data.data));
                props.onLogin('LOGIN', res.data);
                if (localStorage.getItem('previous-link')) {
                    history.push(localStorage.getItem('previous-link'))
                    window.location.reload()
                } else {
                    props.url ? history.push(props.url) : history.push("/home");
                }
            }
        ).catch(error => {
            setLoading(false);
            message.config({
                rtl: true
            })
            message.error(error.response?.data?.errors?.mobile ?? error.response?.data?.errors?.message?? 'حدث خطأ ما');
        })
    }

    const onForgetPassword = (values) => {
        setLoading(true);
        setResetMobile(values.email);
        const body = {
            mobile: values.email
        }
        setMobile(values.phone);
        forgetPasswordMobileApi(body).then(
            res => {
                setLoading(false);
                setForgetPasswordModal(false);
                message.success('تم إرسال الكود ');
                setShowChangePasswordModal(true);
            }
        ).catch(error => {
            setLoading(false);
            message.config({
                rtl: true
            })
            message.error(Object.values(error.response.data?.errors)[0][0] || 'حدث خطأ ما');
        })
    };

    const onChangePassword = (values) => {
        setLoading(true);
        const body = {
            mobile: resetMobile,
            password: values.newPassword,
            password_confirmation: values.confirmPassword,
            code: values.code
        }
        setMobile(values.phone);
        resetPasswordMobileApi(body).then(
            res => {
                setLoading(false);
                setShowChangePasswordModal(false);
                message.success('تم تغيير كلمة المرور بنجاح');
            }
        ).catch(error => {
            setLoading(false);
            message.config({
                rtl: true
            })
            message.error(Object.values(error.response.data?.errors)[0][0] || 'حدث خطأ ما');
        })
    };

    return (
        <div className="login">
            <div className="content">
                <h3>تسجيل الدخول</h3>
                <form className="form">
                    <label htmlFor="phone">رقم الجوال</label>
                    {/* <input type="text" name="phone" placeholder="رقم الجوال أو البريد الإلكترونى" onChange={event => onChangeMobileHandler(event)} /> */}
                    <NumberFormat
                        type="tel"
                        allowEmptyFormatting
                        autocomplete="address-level4"
                        format="(+966)  ##########"
                        autoComplete="off"
                        onChange={event => onChangeMobileHandler(event)}
                    />
                    
                    <span className="error" style={{'visibility': mobileError ? 'visible' : 'hidden'}}>هذا الحقل مطلوب</span>
                    <label htmlFor="password">كلمة المرور</label>
                    <div className="password">
                        <span onClick={togglePassword}>
                            {displayPassword ? 'إخفاء' : 'عرض'}
                        </span>
                        <input 
                            type={displayPassword ? 'text' : 'password'} 
                            name="password" placeholder="كلمة المرور" 
                            onChange={event => onChangePasswordHandler(event)} 
                        />
                    </div>
                    <span className="error" style={{"visibility": passwordError ? 'visible' : 'hidden'}}>هذا الحقل مطلوب</span>
                    <span className="forget-password-text" onClick={()=> setForgetPasswordModal(true)}>نسيت كلمة المرور؟</span>

                    <button onClick={(event) => onLoginHandler(event)}>دخول</button>
                    <Link to="/register" id="register-link"><span>ليس لديك حساب؟ </span> أنشأ حساب الأن</Link>
                </form>
            </div>
            <p>من خلال تسجيل الدخول . فأنك توافق على شروط الأستخدام و سياسة الخصوصية</p>
            {showModal && <ConfirmationModal showModal={showModal} mobile={mobile} url="/home" closeModalHandler={closeModalHandler} />}
            <Backdrop className='backdrop' open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {forgetPasswordModal && <Modal width={430} title="" visible={forgetPasswordModal} footer={null} maskClosable={false} onCancel={() => setForgetPasswordModal(false)}>
                <div className="forget-password">
                    <h3>إسترجاع كلمة المرور</h3>
                    <Form 
                        name="forget-password"
                        className="form"
                        layout="vertical"
                        onFinish={onForgetPassword}
                    >
                        <div className="fields">
                            <div className="field">
                                <Form.Item label="البريد الألكترونى أو رقم الجوال" name="email" rules={[{ required: true, message: 'من فضلك أدخل  البريد الألكترونى أو الجوال' }]}>
                                    <Input placeholder="البريد الألكترونى أو رقم الجوال" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="button-container">
                            <button type="">إرسال</button>
                        </div>
                    </Form>
                </div>
            </Modal>}
            {showChangePasswordModal && <Modal width={430} title="" visible={showChangePasswordModal} footer={null} maskClosable={false} onCancel={() => setShowChangePasswordModal(false)}>
                <div className="change-password">
                    <h3 style={{textAlign: 'center'}}>تغيير كلمة المرور</h3>
                    <Form 
                        name="change-password"
                        className="form"
                        layout="vertical"
                        onFinish={onChangePassword}
                    >
                        <div className="fields">
                            <div className="field">
                                <Form.Item label="كود التأكيد" name="code" rules={[{ required: true, message: 'من فضلك أدخل الكود' }]}>
                                    <Input style={{padding: '7px', borderRadius: '7px', direction: 'ltr',textAlign: 'right'}} placeholder="كود التأكيد" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="fields">
                            <div className="field">
                                <Form.Item label=" كلمة المرور" name="newPassword" 
                                    rules={[
                                        { required: true, message: 'من فضلك أدخل كلمة المرور' },
                                    ]}
                                >
                                    <Input.Password placeholder=" كلمة المرور" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="fields">
                            <div className="field">
                                <Form.Item label="تأكيد كلمة المرور" name="confirmPassword" 
                                    rules={[
                                        { required: true, message: 'من فضلك أدخل تأكيد كلمة المرور' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('كلمة المرور غير متطابقة'));
                                            }
                                        }),
                                    ]}
                                >
                                    <Input.Password placeholder="تأكيد كلمة المرور" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="button-container">
                            <button type="">تغيير</button>
                        </div>
                    </Form>
                </div>
            </Modal>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (type, value) => dispatch({type: type, payload: value})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginWrapper);