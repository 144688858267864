import './result.scss';
import { Skeleton, Empty } from "antd";
import { Rate } from 'antd';
import { useHistory } from 'react-router';


const Result = ({doctors, loading, meta, getDoctorsMethod}) => {
    const history = useHistory();

    const getMoreDoctors = () => {
        getDoctorsMethod(meta?.current_page + 1);
    }

    const navigateToDoctor = (event, id) => {
        event.stopPropagation();
        if (window.location.href.includes('video')) {
            history.push(`/lab/${id}?video=true`);
        } else {
            history.push(`/lab/${id}`)
        }
    }

    const navigateToMedical = (event, id) => {
        event.stopPropagation();
        history.push(`/medical-center/${id}`)
    }

    return(
        <div className="result-container">
            <div className="header-title">
                <p className="">كل الخدمات ({meta?.total})</p>
            </div>

            <div className="labs-results">
                <Skeleton className="" active={true} loading={loading} avatar>
                    {doctors.length == 0 ? (
                        <div className="">
                            <Empty
                                description={
                                <span className="font-Lsmall weight-600 text-theme-color">
                                    لا يوجد خدمات
                                </span>
                                }
                            />
                        </div>
                    ) : (
                        doctors.map((doctor) => (
                            <div key={doctor.id} className="element" onClick={(event) => navigateToDoctor(event, doctor.uuid)}>
                                <div className="doctor-image">
                                    <div className="images-overlay"></div>
                                    <img className="doctor-image-element" src={doctor.image} alt={doctor.name} />
                                    <p>{doctor?.name}</p>
                                </div>
                                <div className="doctor-rate">
                                    <div className="booking">
                                        <span>حجز</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                    {meta?.current_page * 15 < meta?.total ?<div className="more">
                        <button onClick={getMoreDoctors}>عرض المزيد</button>
                    </div> : '' }
                </Skeleton>
                
            </div>

        </div>
    )
}

export default Result;