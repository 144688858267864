import './blog.scss';

const Blog = () => {
    return (
        <div className="blog-container" id="blog">
            <h2>المدونة</h2>
            <div className='blog-cards'>
                <a className="blog-card" href='https://blog.drs-space.sa/%d8%b3%d8%b1%d8%b7%d8%a7%d9%86-%d8%a7%d9%84%d8%ab%d8%af%d9%8a-%d8%a7%d8%b9%d8%b1%d8%a7%d8%b6%d9%87-%d9%88%d8%a5%d9%85%d9%83%d8%a7%d9%86%d9%8a%d8%a9-%d8%b9%d9%84%d8%a7%d8%ac%d9%87/' target='_blank'>
                    <div className="blog-card">
                        <img src={process.env.PUBLIC_URL + '/assets/images/blog.svg'} alt='' />
                        <h3>سرطان الثدي اعراضه وإمكانية علاجه</h3>
                        <p>سرطان الثدي اعراضه وإمكانية علاجه يعتبر سرطان الثدي موضوع اهتمام عالمي لأنه السبب الرئيسي للوفيات المرتبطة بالسرطان بين النساء وعلى الرغم من تزايد معدل الوفيات بسبب هذا المرض، إلا أنه يعتبر من السرطانات القابلة للشفاء فقط إذا تم اكتشاف العلامات المبكرة لسرطان الثدي والبدء في العلاج.</p>
                        <button>اقرأ المزيد</button>
                    </div>
                </a>
                <a className="blog-card" href='https://blog.drs-space.sa/%d8%a7%d9%84%d8%b9%d9%84%d8%a7%d8%ac-%d8%a7%d9%84%d8%b7%d8%a8%d9%8a%d8%b9%d9%8a-%d9%84%d8%a3%d9%85%d8%b1%d8%a7%d8%b6-%d9%88%d8%ac%d8%b1%d8%a7%d8%ad%d8%a7%d8%aa-%d8%a7%d9%84%d8%b9%d8%b8%d8%a7%d9%85/' target='_blank'>
                    <div className="blog-card">
                        <img src={process.env.PUBLIC_URL + '/assets/images/blog.svg'} alt='' />
                        <h3>العلاج الطبيعي لأمراض وجراحات العظام</h3>
                        <p>تعتبر جراحة العظام من العلاجات الطبية والجراحية المهمة في التخلص من أمراض اضطرابات الجهاز العضلي الهيكلي التي تنتج عن الإصابات الرياضية أو حالات مثل متلازمة النفق الرسغي والتهابات المفاصل ومشاكل الركبة والمفاصل والأوتار والأربطة والعظام ويدخل العلاج الطبيعي كعنصر مهم أيضًا في استكمال مرحلة العلاج الطبي والتي تلي العملية الجراحية مباشرة وفيما يلي سنتعرف على معلومات أكثر من خلال هذا المقال.</p>
                        <button>اقرأ المزيد</button>
                    </div>
                </a>
                <a className="blog-card" href='https://blog.drs-space.sa/%d8%a7%d8%b6%d8%b7%d8%b1%d8%a7%d8%a8%d8%a7%d8%aa-%d8%a7%d9%84%d9%86%d9%88%d9%85/' target='_blank'>
                    <div className="blog-card">
                        <img src={process.env.PUBLIC_URL + '/assets/images/blog.svg'} alt='' />
                        <h3>اضطرابات النوم</h3>
                        <p>يعاني كثيرًا منا في بعض الفترات من حياتهم من الأرق أو اضطرابات النوم ولكنه يبقى طبيعيًا إذا زال مع زوال المشكلة التي يعاني منها وإن استمر ملازمًا فإنه يصبح مشكلة صحية لا بد من معالجتها، الأرق او اضطرابات النوم هو موضوع حديثنا في هذا المقال حيث نتناول من خلاله تعريف اضطرابات النوم وأنواع هذه الاضطرابات وطرق علاجها، فتابعوا معنا.</p>
                        <button>اقرأ المزيد</button>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Blog