import './sucess-booking.scss'
import { Link } from 'react-router-dom';

const SuccessBooking = () => {

    return (
        <div className="booking-success">
            <div className="image">
                <div>
                    <img src={process.env.PUBLIC_URL + '/assets/images/check.png'} alt="" />
                </div>
            </div>
            <div className="success-data">
                <h3>تم تأكيد حجزك بنجاح</h3>
                <p>تم إتمام الحجز بنجاح</p>
                <button type="">
                    <Link to="/my-account">تفاصيل الحجز</Link>
                </button>
                <Link to="/home">الصفحة الرئيسية</Link>
            </div>                    
        </div>
    )
}

export default SuccessBooking;