import './lab-history.scss';
import { useEffect, useState } from 'react';
import { getAllLabOrdersApi, showReportApi } from '../../../../data/api/reservation-api';
import { Skeleton, Empty } from "antd";
import { Link } from 'react-router-dom';

const LabHistory = () => {
    const [labs, setLabs] = useState([]);
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(true);
    const [selectedLab, setSelectedLab] = useState({});

    useEffect(() => {
        getReservationReports();
    },[])

    const getReservationReports = () => {
        setSkeletonLoading(true);
        getAllLabOrdersApi(2).then(
            res => {
                setSkeletonLoading(false);
                setLabs(res.data.data)
            }
        )
    }

    const getReportData = (labId) => {
        setShowDetails(false);
        setSkeletonLoading(true);
        showReportApi(labId).then(
            res => {
                setSelectedLab(res.data.data);
                setSkeletonLoading(false);
            }
        )
    }

    return (
        <div className="lab-history">
            <Skeleton className="" active={true} loading={skeletonLoading} avatar>
                {showDetails && (labs.length ? labs?.map((lab, index) => (
                    <div key={index} className="medical-card" onClick={() => getReportData(lab.uuid)}>
                        <div className="medical-header">
                            <h3>تقرير مختبر</h3>
                            <p>{lab.created_at}</p>
                        </div>
                        <div className="medical-data">
                            <img src={lab.lab?.image} alt="" />
                            <div>
                                <p>{lab.lab?.name}</p>
                                <span>{lab.lab?.description}</span>
                            </div>
                        </div>
                    </div>
                )) : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', margin: '30px 0'}}>
                        <Empty
                            description={
                            <span className="font-Lsmall weight-600 text-theme-color">
                                لا يوجد تقارير
                            </span>
                            }
                        />
                    </div>)}
                {!showDetails && 
                    <div className="lab-details-container">
                        <div className="lab-header">
                            <img src={process.env.PUBLIC_URL + '/assets/images/lab-arrow.svg'} alt="" onClick={() => setShowDetails(true)} />
                            <p>تقرير مختبر</p>
                        </div>
                        <div className="lab-details">
                            <div className="lab-image">
                                <div>
                                    <img src={selectedLab?.lab?.image} alt="" />
                                </div>
                                <div className="lab-name">
                                    <h3>{selectedLab?.lab?.name}</h3>
                                    <p>{selectedLab?.lab?.description}</p>
                                </div>
                            </div>
                            <div className="lab-address">
                                <div className="lab-calendar">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/lab-calendar.svg'} alt="" />
                                    <p>{selectedLab?.created_at}</p>
                                </div>
                                <div>
                                    <a target="_blank" href={`https://maps.google.com/?q=${selectedLab?.labe?.latitude},${selectedLab?.labe?.longitude}`}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                                        <p>{selectedLab?.labe?.address}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="lab-report">
                            {/* <div>
                                <img src={process.env.PUBLIC_URL + '/assets/images/medical-history.svg'} alt="" />
                                <p>التقرير الطبى</p>
                            </div>
                            <p></p> */}
                        </div>
                        <div className="lab-result">
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/images/lab-result.svg'} alt="" />
                                <p>نتيجة التحاليل</p>
                            </div>
                            {selectedLab.items?.length && selectedLab.items.map((item, index) => (
                                <div className="lab-items" key={index}>
                                    {item.attachments.map((file, index) => (
                                        <Link to={file.file} target="_blank" key={index}>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/document.png'} alt="" />
                                        </Link>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </Skeleton>
        </div>
    )
}

export default LabHistory;