import './who-are.scss'; 

const WhoAre = () => {
    return (
        <div className="who-are-container">
            <h3>من نحن</h3>
            <ul>
                <li>منصة إلكترونية تتيح لك حجز المواعيد مع المراكز الطبية والمختبرات وإدارتها بكل سهولة</li>
                <li>استشارات اون لاين مع نخبة من الأطباء الكفاءات</li>
                <li>استعراض التقارير الطبية وحفظها</li>
                <li>تصفح العيادات والمختبرات والصيدليات الأقرب لموقعك</li>
                <li>استعرض تقييمات المراكز وامكانية مشاركة تجربتك مع الاخرين</li>
                <li>قارن افضل العروض الحصرية واختر الأفضل</li>
            </ul>
        </div>
    );
}

export default WhoAre;