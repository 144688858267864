import './online-search.scss';
import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Input } from 'antd';
import { medicalSpecialties } from '../../../../../data/api/search-api';
import { Link } from 'react-router-dom';


const { Option } = Select;

const OnlineSearch = () => {
    const [ specialties, setSpecialties ] = useState([]);
    const [ specialty, setSpecialty ] = useState(null);
    const [ doctor, setDoctor ] = useState(null);

    useEffect(() => {
        getMedicalSpecialties();
    }, []);

    const getMedicalSpecialties = () => {
        medicalSpecialties().then(
            res => {
                setSpecialties(res.data.data);
            }
        )
    }

    return(
        <div className="online-search">
            <div className="">
                <label for="">التخصص</label>
                <Select style={{ width: '100%' }} placeholder="التخصص" onChange={(value) => setSpecialty(value)}>
                    {specialties.map((element, index) => (
                        <Option key={index} value={element.id}>{element.name}</Option>
                    ))}
                </Select>
            </div>
            <div className="">
                <label for="">أو أكتب إسم الدكتور</label>
                <Input placeholder="أكتب إسم الدكتور" onChange={(event) => setDoctor(event.target.value)} />
            </div>

            <div className="">
                <button className="button">
                    <Link to={`/video-call?specialty=${specialty}&doctor=${doctor}`}>إبحث</Link>
                </button>
            </div>
        </div>
    )
}

export default OnlineSearch;