import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import { BellFilled, LoadingOutlined } from "@ant-design/icons";
import { Popover, Menu, Badge, Empty, message } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./notification.scss";
import { getNotifications, getUnreadNotificationsCount, markNotificationAsRead } from "../../data/api/global-api";

const NotificationsSection = () => {
  const [notifications, setNotifications] = useState([]);
  const [notifications_count, setNotificationsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const user_info = useSelector((state) => state?.user);

  useEffect(() => {
    const pusher = new Pusher("0053930143cf0bff7fa3", {
      cluster: "eu",
    });

    // new notification
    const channel = pusher.subscribe(`patient_${user_info?.id}`);
    channel.bind("new_notification", (data) => {
      setNotificationsCount(notifications_count + 1);
      setNotifications([
        {
          ...data?.notification,
          title: data?.notification?.title?.ar,
          description: data?.notification.description?.ar,
          read_at: "",
        },
        ...notifications,
      ]);
      return () => {
        pusher.unsubscribe(`medical_${user_info?.id}`);
      };
    });
  }, []);
  // %%%%%%%%%%% Pusher End %%%%%%%%%%%%

  useEffect(() => {
    getNotificationsF();
    getUnreadNotificationsCountF();
  }, []);

  const getNotificationsF = () => {
    getNotifications()
      .then((res) => {
        setNotifications(res?.data?.data);
      })
      .catch((err) => {});
  };
  const getUnreadNotificationsCountF = () => {
    setLoading(true);
    getUnreadNotificationsCount()
      .then((res) => {
        setLoading(false);
        setNotificationsCount(res?.data?.count);
      })
      .catch((err) => {});
  };
  const markNotificationAsReadF = () => {
    markNotificationAsRead()
      .then((res) => {
        message.destroy();
      })
      .catch((err) => {
        message.destroy();
      });
  };
  const onNotificationMenuVisibleChange = (visiblity) => {
    setVisible(visiblity);
    if (visiblity) {
    } else {
      closeNotification();
    }
  };
  const closeNotification = () => {
    if (notifications_count !== 0) {
      setNotifications(
        notifications.map((notification) => ({
          ...notification,
          read_at: true,
        }))
      );
      setNotificationsCount(0);
      markNotificationAsReadF();
    }
  };
  const NotificationsMenu = (
    <div className="notification-menu-wrapper">
      {notifications.length === 0 ? (
        <div>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className="font-small">
                {loading ? <LoadingOutlined /> : "   لا توجد اشعارات"}
              </span>
            }
          />
        </div>
      ) : (
        <Menu
          onSelect={() => {}}
          className="notifications-menu"
          items={notifications.map((notification) => ({
            key: notification.id,
            className: `${!notification.read_at && "unread-notification"}`,
            label: (
              <Link
                to='/my-account'
                className="notification-item"
                onClick={() => {
                  setVisible(false);
                  closeNotification();
                }}
              >
                <div className="notification-title mb-2">
                  <span className="font-small weight-700 text-theme-color" style={{ textAlign: "right", display: 'block' }}>
                    {notification.title}
                  </span>
                </div>
                <div className="notification-description mb-1">
                  <span className="font-small weight-500 text-gray-500" style={{ textAlign: "right", display: 'block' }}>
                    {notification.description}
                  </span>
                </div>
                <div className="notification-time d-flex justify-content-end">
                  <span
                    className="font-Xsmall weight-500 text-gray-400 d-inline-block"
                    style={{ direction: "ltr" }}
                  >
                    {notification.created_at}
                  </span>
                </div>
              </Link>
            ),
          }))}
        />
      )}
    </div>
  );

  return (
    <>
      <Popover
        content={NotificationsMenu}
        placement="bottom"
        trigger="click"
        onVisibleChange={onNotificationMenuVisibleChange}
        overlayClassName="notifications-popover"
        visible={visible}
      >
        <Badge count={notifications_count}>
          <span className="font-large link-gray-500">
            <BellFilled style={{fontSize: '18px'}} />
          </span>
        </Badge>
      </Popover>
    </>
  );
}

export default NotificationsSection;
