// import AgoraRTC from 'agora-rtc-sdk';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// import { generateChannel } from '../../data/api/reservation-api';

const VideoCall = () => {
    const { user } = useSelector(state => state);
    let rtc = {
        client: null,
        joined: false,
        published: false,
        localStream: null,
        remoteStreams: [],
        params: {}
    }
    
    let option = {
        appID: 'ef455056beaf4559992e62e34735226d',
        channel: 'reservation-100',
        uid: JSON.parse(user).id
    }

    useEffect(() => {
        // joinChannel();
    }, [])
      
    // const joinChannel = () => {
    //     generateChannel(JSON.parse(user).id, 'reservation-100').then(
    //         res => {
    //             console.log(res);
    //             rtc.client = AgoraRTC.createClient({mode: 'live', codec: 'h264'});
    //             rtc.client.init(option.appID, function() {
    //             console.log('init successfully');
            
    //             rtc.client.join(res.data.token ? res.data.token: null,
    //                 option.channel, option.uid ? +option.uid : null, function (uid) {
    //                 rtc.params.uid = uid;
    //                 rtc.client.setClientRole('host')
    //                 rtc.localStream = AgoraRTC.createStream({
    //                     streamID: rtc.params.uid,
    //                     audio: true,
    //                     video: true,
    //                     screen: false
    //                 })
            
    //                 rtc.localStream.init(function () {
    //                     console.log('localStream init');
    //                     rtc.localStream.play('local_stream');
    //                     rtc.client.publish(rtc.localStream, function(err) {
    //                     console.log('publish failed');
    //                     console.log(err);
    //                     })
    //                 })
    //                 }, (err) => {
    //                     console.log(err);
    //                 }
    //             )
    //             })
    //         })
    //     }

    return (
        <div style={{width: '100%', maxHeight: '76vh'}}>
            <div id="local_stream" className="local_stream" style={{width: '100%', height: '100%'}}></div>
        </div>
    )
}

export default VideoCall;