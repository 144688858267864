import Axios from "axios";
// Axios.interceptors.request.use(
//   config => {
//     config.headers.Token = `Bearer test`;
//     return config;
//   }
// )

const BaseApi = Axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
  headers: {
    "x-api-key":
      "ZCCbKOUHrcU2HxmZgqft70ba4iy5lhJj6BLL5xcfOtRLZH5iQKzHoPSb44mBgxfT",
    "Content-Type": " application/json",
    Accept: "application/json",
    "Accept-Language": "ar",
  },
});

export default BaseApi;
