import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { getDoctorAppointmentApi } from '../../../../data/api/search-api';
import { useEffect, useState } from 'react';
import AppointmentSlider from '../../../appointment-slider/appointment-slider';
import './doctor-element.scss'
import Login from '../../../auth/page/login/login';
import { Modal } from 'antd';
import { useLocation } from "react-router-dom";
import { connect } from 'react-redux';

const DoctorElement = ({doctor, user}) => {
    const history = useHistory();
    const [appointments, setAppointments ] = useState([]);
    const [appointmentsBody, setAppointmentsBody] = useState({});
    const [selectedTime, setSelectedTime] = useState();
    const [showModal, setShowModal] = useState(false);
    const [appointmentLoaded, setAppointmentLoaded] = useState(false)
    const search = useLocation().search;
    const video = new URLSearchParams(search).get('video');

    const navigateToDoctor = (event, id) => {
        event.stopPropagation();
        if (window.location.href.includes('video')) {
            history.push(`/doctor/${id}?video=true`);
        } else {
            history.push(`/doctor/${id}`)
        }
    }

    useEffect(() => {
        getAppointments()
    }, [setAppointmentLoaded])

    const getAppointments = (video = false) => {
        getDoctorAppointmentApi(doctor.uuid, null, video).then(
            (res) => {
                const appointmentArray = null ? res.data.data.filter(element => element.offer) : res.data.data
                setAppointments(appointmentArray)
                const result = appointmentArray.reduce(function (r, a) {
                    r[a.day] = r[a.day] || [];
                    r[a.day].push(a);
                    return r;
                }, Object.create(null));
                const resultKeys = Object.keys(result).sort();
                const resultSorted = {}
                resultKeys.forEach(key => {
                    resultSorted[key] = result[key];
                })
                setAppointmentLoaded(true)
                setAppointmentsBody(resultSorted);
            }
        )
    }

    const closeModalHandler = () => {
        setShowModal(false);
    }

    const onBookingDoctor = (event) => {
        setSelectedTime(event)
        if (!event) {
            return;
        }

        if (user) {
            history.push("/booking-doctor/" + event + '/' + doctor.uuid + `?video=${video}`);
        } else {
            localStorage.setItem('previous-link', history?.location?.pathname)
            setShowModal(true)
        }
    }

    return (
        <div key={doctor?.id} className={doctor?.is_featured && doctor?.isFeaturedElement? 'is-featured element' : 'element'}>
            {doctor?.is_featured ? doctor?.isFeaturedElement && <p className='special'>مميز</p> : ''}
            <div className="doctor-image" onClick={(event) => navigateToDoctor(event, doctor?.uuid)}>
                <div className="images">
                    <img className="doctor-image-element" src={doctor?.image} alt={doctor?.name} />
                    {/* {doctor?.medical_center?.image ? <img className="medical-center-image" onClick={(event) => navigateToMedical(event, doctor?.medical_center?.uuid)} src={doctor?.medical_center?.image} alt="" /> : ''} */}
                </div>
            </div>
            <div className="doctor-data" onClick={(event) => navigateToDoctor(event, doctor?.uuid)}>
                <div className="doctor-name">
                    <p><span>دكتور </span>{doctor?.name}</p>
                    {/* {doctor.clinic_allow ? <img src={process.env.PUBLIC_URL + '/assets/images/audio-call.svg'} alt="" /> : ''} */}
                    {doctor?.video_allow ? <img src={process.env.PUBLIC_URL + '/assets/images/video-call.svg'} alt="" /> : ''}
                </div>
                { doctor?.title_details ?<div className="doctor-description">
                    <p>{doctor?.title_details}</p>
                </div> : ''}
                { doctor?.description ?<div className="doctor-description">
                    <img src={process.env.PUBLIC_URL + '/assets/images/doctor-name.svg'} alt='' />
                    <p>{doctor?.description}</p>
                </div> : ''}
                <div className="doctor-description">
                    <img src={process.env.PUBLIC_URL + '/assets/images/price.svg'} alt='' />
                    {window.location.href.includes('video') ? <p>الكشف: <span>{doctor?.video_fees} ريال</span></p> : <p>الكشف: <span>{doctor?.clinic_fees} ريال</span></p>}
                </div>
                <div className="doctor-price" style={{"display": "flex", "flexWrap": "nowrap"}}>
                    <a target="_blank" href={`https://maps.google.com/?q=${doctor?.latitude},${doctor?.longitude}`}>
                        <img src={process.env.PUBLIC_URL + '/assets/images/address.svg'} alt="" />
                        <p>{doctor?.address}</p>
                    </a>
                </div>
                { doctor?.medical_center?.name ? <div className="doctor-description doctor-medical">
                    <p>{doctor?.medical_center?.name}</p>
                </div> : ''}
                {/* {doctor?.medical_specialties && doctor?.medical_specialties[0]?.name ? <div className="doctor-description">
                    <img src={process.env.PUBLIC_URL + '/assets/images/examine.svg'} alt="" />
                    <p>{doctor?.medical_specialties[0]?.name}</p>
                </div> : ''}
                {doctor?.medical_center?.name ? <div className="medical" onClick={(event) => navigateToMedical(event, doctor?.medical_center?.uuid)}>
                    <p>إسم المركز الصحى</p>
                    <p>{doctor?.medical_center?.name}</p>
                </div> : ''} */}
            </div>
            <div className="appointments">
                <div className='timing'>
                    {appointmentLoaded ? <AppointmentSlider onSelectOffer={null} onBookingDoctor={onBookingDoctor} appointments={appointmentsBody} /> : ''}
                </div>
            </div>
            {showModal && <Modal title="" visible={showModal} footer={null} maskClosable={false} onCancel={closeModalHandler} width={600}>
                    <Login url={`/booking-doctor/${selectedTime}/${doctor.uuid}`} />
            </Modal>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(DoctorElement);